import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper, Grid, Switch} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ActionCellRenderer from '../components/action-cell-renderer'

import getGridConfig from './grid-config'
import PurchaseDetail from './purchase-detail'

import Moment from 'moment-timezone'

import callAccountsApi from '../data-provider'

const S3 = require('../components/aws-s3')


const styles = theme => ({
  paper: {
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    margin : 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  errorMessage: {
    color:"red"
  }
})

class Purchases extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {selectedPurchase:null,purchases:[],detail_mode:"view",onlyUnassignedPurchases:false}
  }

  getPurchases = async () => {
    this.setState({purchases:[]})
    let purchases = await callAccountsApi("purchases","getPurchases")
    this.setState({purchases})
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getPurchases()
  }

  onRowDoubleClicked = row => {
    let selectedPurchase = row.data
    this.setState({selectedPurchase,detail_mode:"view"})
  }

  toggleUnassignedPurchases = () => {
    let onlyUnassignedPurchases = !this.state.onlyUnassignedPurchases
    this.setState({onlyUnassignedPurchases})
  }

  closeSelectedPurchase = refresh => {
    this.setState({selectedPurchase:null})

    if (refresh === true)
    {
      this.getPurchases()
    }
  }

  view = data => {
    this.setState({selectedPurchase:data,detail_mode:"view"})
  }

  getPurchaseImage = async purchase => {
    let key = `purchases/${Moment(purchase.purchase_date).year()}/${Moment(purchase.purchase_date).month()+1}/${purchase.scan_image}`
     
    let scan = await S3.getFile('data.accounts.smartix.uk',key)

    if (scan)
    {
      let purchaseScanType = scan.ContentType
      let purchaseScanBuffer = Buffer.from(scan.Body)
      let blob = new Blob([purchaseScanBuffer], { type: purchaseScanType })
      return URL.createObjectURL(blob)
    }
  }

  print = async purchase => {
    let blob_url = await this.getPurchaseImage(purchase)

    var iframe = document.getElementById('pdfiframe')
    iframe.src=blob_url
    document.body.appendChild(iframe)
    iframe.onload = function() {
      iframe.contentWindow.print()
    }
  }

  download = async purchase => {
    let blob_url = await this.getPurchaseImage(purchase)
    let extension = purchase.scan_image.split(".").pop()
    let filename = `${purchase.detail}-${Moment(purchase.purchase_date).format("DD-MMM-YYYY")}.${extension}`

    const a = document.createElement('a')
    a.href = blob_url
    a.download = filename

    a.click()
  }

  actionClickHandler = (action,data) => {
    switch(action)
    {
      case "view" : return this.view(data)
      case "print" : return this.print(data)
      case "download" : return this.download(data)
      case "link" : return this.gotoTransaction(data)
      default: {}
    }
  }

  gotoTransaction = data => {
    if (data.transaction_id)
    {
      this.props.history.push('/transactions/' + data.transaction_id)
    }

    if (data.expense_id)
    {
      this.props.history.push('/expenses/' + data.expense_id)
    }
  }

  openNewPurchaseForm = () => {
    let selectedPurchase = {
      purchase_date : new Date(),
      purchase_type : '',
      amount : 0,
      vat : 0,
      scan_image : null
    }

    this.setState({selectedPurchase,detail_mode:"edit"})
  }

  render() {
    const { classes } = this.props
    const GridConfig = getGridConfig(this.actionClickHandler,this.openNewPurchaseForm)

    return (
      <React.Fragment>

        <span style={{float:"right",color:'#fff'}}>
          <Grid component="label" container item alignItems="center" spacing={1}>
            <Grid item>All Purchases</Grid>
            <Grid item>
              <Switch
                checked={this.state.onlyUnassignedPurchases}
                onChange={this.toggleUnassignedPurchases}
                color="primary"
              />
            </Grid>
            <Grid item>Unassigned Purchases</Grid>
          </Grid>
        </span>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.purchases.filter(p => !this.state.onlyUnassignedPurchases || (!p.transaction_id && !p.expense_id))}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams : {
                            // only show count and sum ('min', 'max', 'avg' won't be shown)
                            aggFuncs : ['min', 'max', 'avg', 'sum']
                        }
                    }
                ]
              }}
              frameworkComponents = {{
                actionCellRenderer : ActionCellRenderer,
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              >
            </AgGridReact>
          </div>

        </Paper>

        <PurchaseDetail open={this.state.selectedPurchase !== null} purchase={this.state.selectedPurchase} close={this.closeSelectedPurchase} mode={this.state.detail_mode} link={true}/>
        <iframe id="pdfiframe" title="pdf"/>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Purchases))