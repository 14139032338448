import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import { AgGridReact } from 'ag-grid-react';
import { Snackbar} from '@material-ui/core'

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import getGridConfig from './grid-config-popup'

import {Dialog,DialogTitle,DialogContent,DialogActions, IconButton, Table, TableCell, TableBody, TableRow, TableHead, Tooltip} from '@material-ui/core'

import PrintIcon from '@material-ui/icons/Print'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import RecalcIcon from '@material-ui/icons/Sync'
import {RiMailSendLine as SubmitIcon} from "react-icons/ri"
import ApplyDifferencesIcon from '@material-ui/icons/CheckCircleOutline'
import { ImLink as LinkIcon } from "react-icons/im"

import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import ValueFormatter from '../components/value-formatter'

import callAccountsApi from '../data-provider'

import Config from '../config'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  },
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
})


function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    vat_id : null,
    item_date:null,
    vat_return_category:null,
    detail:"TOTALS",
    amount_in : 0,
    amount_out : 0,
    vat_out : 0,
    vat_in : 0,
  }
  
  gridApi.forEachNode(row => {
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
    result.vat_out += row.data.vat_out ? parseFloat(row.data.vat_out) : 0
    result.vat_in += row.data.vat_in ? parseFloat(row.data.vat_in) : 0
  })

  result.amount_in = result.amount_in.toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)
  result.vat_out = result.vat_out.toFixed(2)
  result.vat_in = result.vat_in.toFixed(2)

  return [result]
}


class VATDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {originalVATReturn:{},vatreturn:{},edit:false,mode:'view',transactions:[],differences:{},message:""}
  }

  componentDidMount() {
    let userData = Config.getConfig('userData')
    let edit = userData.edit_right === 1
    this.setState({edit,mode:this.props.mode,transactions:[]})
  }

  componentDidUpdate(prevProps) {
    let vatreturn = this.props.vatreturn
    let originalVATReturn = {...this.props.vatreturn}

    if (this.props.mode !== prevProps.mode)
    {
      this.setState({mode:this.props.mode})
    }

    if ((vatreturn && this.props.vatreturn !== prevProps.vatreturn))
    {
      this.setState({originalVATReturn,vatreturn,mode:this.props.mode,transactions:[],differences:{}})
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getVATReturnTransactions()
  }

  getVATReturnTransactions = async () => {
    let {transactions,differences} =  await callAccountsApi("vat","getVATReturnTransactions",{vat_id:this.state.vatreturn.vat_id})
    this.setState({transactions,differences})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  startEditMode = () => {
    this.props.actionClickHandler("edit")
  }

  saveVATReturn = async () => {
    let vatreturn = this.state.vatreturn
    let originalVATReturn = this.state.originalVATReturn

    console.log("vatreturn",JSON.stringify(vatreturn,null,2))
    console.log("originalVATReturn",JSON.stringify(originalVATReturn,null,2))

    for (let f in vatreturn) // Only save if something has changed
    {
      if (vatreturn[f] !== originalVATReturn[f])
      {
        await callAccountsApi("vat","saveReturn",{vatreturn})
        break
      }
    }

    this.props.close(true,vatreturn)
  }

  download = async () => {
    this.props.actionClickHandler("download",this.props.vatreturn)
  }

  print = async () => {
    this.props.actionClickHandler("print",this.props.vatreturn)
  }

  gotoTransaction = () => {
    this.props.actionClickHandler("link",this.props.vatreturn)
  }

  handleChange = (field,value) => {
    let vatreturn = this.state.vatreturn
    vatreturn[field] = value

    vatreturn.vat_due = vatreturn.vat_on_sales + vatreturn.vat_on_acquisitions - vatreturn.vat_on_purchases

    this.setState({vatreturn})
  }
   
  numberSort = (num1,num2) => {
    return num1 - num2
  }

  inputWithDecimal = field => {
    return (
      <CurrencyTextField id={field} name={field} currencySymbol="£" decimalCharacter="." digitGroupSeparator="," minimumValue="0" outputFormat="number" value={this.state.vatreturn[field]} onChange={(event,value) => this.handleChange(field,value,event)}/>
    )
  }

  inputNoDecimal = field => {
    return (
      <CurrencyTextField id={field} name={field} currencySymbol="£" decimalCharacter="." digitGroupSeparator="," minimumValue="0" outputFormat="number" decimalPlaces={0} value={this.state.vatreturn[field]} onChange={(event,value) => this.handleChange(field,value,event)}/>
    )
  }

  hasDifferences = () => {
    for (let c in this.state.differences)
    {
      if (this.state.differences[c] !== 0 )
      {
        return true
      }
    }

    return false
  }

  recalculateVAT = () => {
    let vat_on_sales = 0
    let vat_on_purchases = 0
    let total_sales = 0
    let total_purchases = 0

    this.state.transactions.forEach(t => {
      if (t.amount_in)
      {
        total_sales += t.amount_in

        if (t.vat)
        {
          vat_on_sales += t.vat
        }
      }

      if (t.amount_out)
      {
        total_purchases += t.amount_out

        if (t.vat)
        {
          vat_on_purchases += (t.vat * -1)
        }
      }
    })

    let vatreturn = {
      vat_id : this.state.vatreturn.vat_id,
      vat_on_sales : parseFloat(vat_on_sales.toFixed(2),10),
      vat_on_purchases : parseFloat(vat_on_purchases.toFixed(2),10),
      vat_on_acquisitions : 0.0,
      total_sales : parseInt(total_sales.toFixed(0),10),
      total_purchases : parseInt(total_purchases.toFixed(0),10),
      sales_non_uk : 0,
      purchases_non_uk : 0,
      vat_due : parseFloat((vat_on_sales - vat_on_purchases).toFixed(2),10),
    }

    this.setState({vatreturn})
  }
  
  submitToHmrc = async () => {
    try
    {
      await callAccountsApi("vat","submitVATReturn",{vat_id:this.props.vatreturn.vat_id,authtoken:this.props.authtoken,mode:Config.getConfig("hmrc_mode")})
      this.props.close(true,this.props.vatreturn)
    }
    catch(err)
    {
      console.log("Error submitting VAT return",JSON.stringify(err.response.data,null,2))
      this.setState({message:"Error submitting VAT return.\n\n" + err.response.data.errors[0].message})
    }
  } 

  applyDifferences = () => {
    let vatreturn = this.state.vatreturn
    let differences = this.state.differences

    vatreturn.vat_on_sales += differences.vat_on_sales
    vatreturn.vat_on_purchases += differences.vat_on_purchases

    this.setState({vatreturn})
  }

  handleCloseMessage =  () => {
    this.setState({message:""})
  }

  render() {
    const { classes, open, close } = this.props
    const vatreturn = this.state.vatreturn

    let GridConfig = getGridConfig()

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">
        {this.state.vatreturn.vat_id && 
          <div>VAT ID : {this.state.vatreturn.vat_id}</div>
        }

      </DialogTitle>
      <DialogContent>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />

        <Table className={classes.table}>
          <TableHead>
          <TableRow>
              <TableCell className="tableHeader">Period Start</TableCell>
              <TableCell>{ValueFormatter.formatDate(vatreturn.period_start)}</TableCell>
              <TableCell className="tableHeader">Period End</TableCell>
              <TableCell>{ValueFormatter.formatDate(vatreturn.period)}</TableCell>
            </TableRow>

              <TableRow>
                <TableCell className="tableHeader">Submitted</TableCell>
                <TableCell>{ValueFormatter.formatDate(vatreturn.processingDate)}</TableCell>
                <TableCell className="tableHeader">Paid</TableCell>
                <TableCell>{ValueFormatter.formatDate(vatreturn.transaction_date)}</TableCell>
              </TableRow>

          </TableHead>
        </Table>

        <Table>
          <TableBody>
            {this.props.mode === "edit" &&
              <TableRow>
                <TableCell className="tableHeader" colSpan={2}></TableCell>
                <TableCell className="tableHeader">This Period</TableCell>
                <TableCell className="tableHeader">Correction</TableCell>
              </TableRow>
            }

            <TableRow>
              <TableCell>VAT due on sales and other outputs</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>1</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoney(vatreturn.vat_on_sales)}</React.Fragment>, edit: <React.Fragment>{this.inputWithDecimal("vat_on_sales")}</React.Fragment>}[this.props.mode]}</TableCell>
              {this.props.mode === "edit" && <TableCell>{ValueFormatter.formatMoney(this.state.differences.vat_on_sales)}</TableCell>}
            </TableRow>

            <TableRow>
              <TableCell>VAT due on aquisitions from other EC Member States</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>2</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoney(vatreturn.vat_on_acquisitions)}</React.Fragment>, edit: <React.Fragment>{this.inputWithDecimal("vat_on_acquisitions")}</React.Fragment>}[this.props.mode]}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total VAT due (the sum of boxes 1 and 2)</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>3</TableCell>
              <TableCell>{ValueFormatter.formatMoney(vatreturn.vat_on_sales + vatreturn.vat_on_acquisitions)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>VAT reclaimed on purchases and other inputs</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>4</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoney(vatreturn.vat_on_purchases)}</React.Fragment>, edit: <React.Fragment>{this.inputWithDecimal("vat_on_purchases")}</React.Fragment>}[this.props.mode]}</TableCell>
              {this.props.mode === "edit" && <TableCell>{ValueFormatter.formatMoney(this.state.differences.vat_on_purchases)}</TableCell>}
            </TableRow>

            <TableRow>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>Net VAT to be paid to Customs or reclaimed by you (the sum of boxes 3 and 4)</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>5</TableCell>
              <TableCell style={{fontWeight:'bold'}}>{ValueFormatter.formatMoney(vatreturn.vat_due)}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total value of sales and all other outputs excluding any VAT</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>6</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoneyNoDecimal(vatreturn.total_sales)}</React.Fragment>, edit: <React.Fragment>{this.inputNoDecimal("total_sales")}</React.Fragment>}[this.props.mode]}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total value of purchases and all other inputs excluding any VAT</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>7</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoneyNoDecimal(vatreturn.total_purchases)}</React.Fragment>, edit: <React.Fragment>{this.inputNoDecimal("total_purchases")}</React.Fragment>}[this.props.mode]}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total value of supplies of goods and related costs, excluding any VAT to other EC member states</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>8</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoneyNoDecimal(vatreturn.sales_non_uk)}</React.Fragment>, edit: <React.Fragment>{this.inputNoDecimal("sales_non_uk")}</React.Fragment>}[this.props.mode]}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total value of acquisitions of goods and related costs, excluding any VAT from other EC member states</TableCell>
              <TableCell style={{backgroundColor:'#1B9A6E',color:'white'}}>9</TableCell>
              <TableCell>{{view:<React.Fragment>{ValueFormatter.formatMoneyNoDecimal(vatreturn.purchases_non_uk)}</React.Fragment>, edit: <React.Fragment>{this.inputNoDecimal("purchases_non_uk")}</React.Fragment>}[this.props.mode]}</TableCell>
            </TableRow>

          </TableBody>
        </Table>

        <div className="ag-theme-alpine" style={ {height: '300px', width: '100%'} }>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.state.transactions}
          columnDefs={GridConfig.columnDefs}
          components = {{
            customPinnedRowRenderer: CustomPinnedRowRenderer,
          }}
          >
        </AgGridReact>
        </div>

      </DialogContent>

      <DialogActions>
        {this.state.vatreturn &&
          <React.Fragment>
            {
              this.state.edit && 
              {
                "view":
                <React.Fragment>
                  <Tooltip title="edit">
                    <IconButton onClick={this.startEditMode} color="primary">
                      <EditIcon/>
                    </IconButton>
                  </Tooltip>
                  
                  { (this.state.vatreturn && !this.state.vatreturn.processingDate && this.props.authtoken) &&
                    <Tooltip title="submit to HMRC">
                    <IconButton onClick={this.submitToHmrc} color="primary">
                      <SubmitIcon/>
                      <p className="vatLegalText">When you submit this VAT information you are making a legal
                      declaration that the information is true and complete. A false declaration
                      can result in prosecution.</p>
                    </IconButton>
                  </Tooltip>

                }
              </React.Fragment>
              
              ,
                "edit":
                <React.Fragment>
                  {this.state.transactions.length > 0 &&
                  <Tooltip title="recaculate VAT figures">
                    <IconButton onClick={this.recalculateVAT} color="primary">
                      <RecalcIcon/>
                    </IconButton>
                  </Tooltip>
                  }
                  {this.hasDifferences() && 
                    <Tooltip title="apply suggested corrections">
                      <IconButton onClick={this.applyDifferences} color="primary">
                        <ApplyDifferencesIcon/>
                      </IconButton>
                    </Tooltip>
                  }


                </React.Fragment>
              }[this.state.mode]
            }

            <div style={{flex: '1 0 0'}} />
              <span className={classes.errorMessage}>{this.state.errorMessage}</span>
            <div style={{flex: '1 0 0'}} />

            {
              {
                "view" :
                <React.Fragment>
                  <Tooltip title="download VAT return PDF">
                    <IconButton onClick={this.download} color="default">
                      <DownloadIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="print VAT return">
                    <IconButton onClick={this.print} color="default">
                      <PrintIcon/>
                    </IconButton>
                  </Tooltip>
                  {(this.props.vatreturn && this.props.vatreturn.transaction_id) &&
                    <Tooltip title="view payment in transactions">
                      <IconButton onClick={this.gotoTransaction} color="default" disabled={this.state.vatreturn.transaction_id === null}>
                        <LinkIcon/>
                      </IconButton>
                    </Tooltip>
                  }
                </React.Fragment>,
                "edit" :
                  <React.Fragment>
                    <Tooltip title="save changes">
                      <IconButton onClick={this.saveVATReturn} color="primary">
                        <SaveIcon/>
                      </IconButton>
                    </Tooltip>
                </React.Fragment>
              }[this.state.mode]
            }

            <IconButton onClick={close} color="primary">
              <CloseIcon/>
            </IconButton>
          </React.Fragment>
        }
      </DialogActions>
    </Dialog>
  )
  }
}

export default withStyles(styles)(VATDetail)