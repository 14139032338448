import React, { Component } from 'react'
import {Button} from '@material-ui/core'
import VATIcon from '@material-ui/icons/AccountBalance'
import PurchaseIcon from '@material-ui/icons/ShoppingCart'
import { RiMoneyPoundBoxFill as InvoiceIcon } from 'react-icons/ri'

class LinksCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.btnClickedHandler = this.btnClickedHandler.bind(this)
  }

  btnClickedHandler(action,id) {
   this.props.clicked(action,id)
  }

  formatLinks = data => {
    if (data.purchases && data.purchases.length) { return this.getLinkButtons('purchase',data.purchases,<PurchaseIcon/>) }
    if (data.invoices && data.invoices.length) { return this.getLinkButtons('invoice',data.invoices,<InvoiceIcon/>) }
    if (data.vatreturn && data.vatreturn.length) { return this.getLinkButtons('vatreturn',data.vatreturn,<VATIcon/>) }
    return []
  }
  
  getLinkButtons = (path,links,icon) => {
    try
    {
      return links.map((l,i) => <Button onClick={event => this.btnClickedHandler(path,l.id)} startIcon={icon} key={i}>{l.text}</Button>)  
    }
    catch(err)
    {
      return [<span>ERROR!!!!!! {JSON.stringify(links)}</span>]
    }
  }


  render() {
    let links = this.formatLinks(this.props.data)

    return links ? (
      <div>
        {!links.length && <span>{this.props.value}</span>}
        {links.map(i => i)}
      </div>
    )
    :
    (<div/>)
  }
}

export default LinksCellRenderer