import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {Dialog,DialogTitle,DialogContent,DialogActions, IconButton, Select, MenuItem, InputLabel, FormControl, Grid, TextField, Switch, FormControlLabel} from '@material-ui/core'
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import callAccountsApi from '../data-provider'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  errorMessage: {
    color:"red"
  }
})

const blankDetails = {
  details:"",
  amount:"",
  charge_vat: true,
  vat:"",
}

class EditInvoiceForm extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {invoice:null,inactiveClients:false,clients:[],users:[],errorMessage:"",vatRate:0,initialising:false}
  }

  componentDidMount() {
    this.setState({invoice:null,initialising:false},() => {
      this.init()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.invoice !== prevProps.invoice)
    {
      this.init()
    }
  }

  init = async () => {

    if (this.state.initialising)
    {
      return
    }

    this.setState({invoice:null,errorMessage:"",initialising:true})

    let promises = []

    promises.push(this.getClients())
    promises.push(this.getUsers())
  
    let [default_client_id] = await Promise.all(promises)  
    
    let invoice

    if (this.props.invoice && this.props.invoice.invoice_id)
    {
      invoice = {
        invoice_id : this.props.invoice.invoice_id,
        invoice_date : this.props.invoice.invoice_date,
        client_id : this.props.invoice.client_id,
        details : await this.getInvoicedetails(this.props.invoice.invoice_id),
        invoicenumber : this.props.invoice.invoicenumber,
      }
    }
    else
    {
      invoice = {details:[{...blankDetails}],client_id:default_client_id,invoice_date : (new Date())}
    }

    let vatRate = await callAccountsApi("invoices","getVATRate",{date:invoice.invoice_date})
    this.setState({invoice,inactiveClients:false,vatRate,initialising:false})
  }

  getInvoicedetails = async invoice_id => {
    let details = await callAccountsApi("invoices","getInvoiceDetails",{invoice_id})

    details.map(d => {
      d.amount = parseFloat(d.amount)
      d.vat = parseFloat(d.vat)
      return d
    })

    return details
  }

  getClients = async () => {
    let clients = await callAccountsApi("clients","getClients")

    if (!this.state.inactiveClients)
    {
      clients = clients.filter(c => c.active)
    }

    this.setState({clients})
    return clients[0].client_id
  }

  getUsers = async () => {
    let users = await callAccountsApi("users","getUsers")
    users = users.filter(u => u.active && u.employee)
    this.setState({users})
    return users[0].user_id
  }

  submitInvoice = async () => {
    let errorMessage = ""

    for (let d=0;d<this.state.invoice.details.length;d++)
    {
      if (!this.state.invoice.details[d].details.length)
      {
        errorMessage = "No detail text for item " + (d+1).toString()
      }
    }

    this.setState({errorMessage})

    console.log(this.state.invoice)

    if (!errorMessage.length)
    {
      let invoice_id = (await callAccountsApi("invoices","saveInvoice",{invoice:this.state.invoice})).invoice_id
      this.props.handleSavedInvoice(invoice_id)
      this.closeDialog()
    }
  }

   
  handleInvoiceChange = event => {
    let invoice = this.state.invoice
    invoice[event.target.name] = event.target.value
    this.setState({invoice})
  }

  handleInvoiceDateChange = async date => {
    let invoice = this.state.invoice
    invoice.invoice_date = date

    let vatRate = await callAccountsApi("invoices","getVATRate",{date})
    this.setState({invoice,vatRate})
  }

  handleDetailsChange = (event,index) => {
    let invoice = this.state.invoice
    invoice.details[index][event.target.name] = event.target.value
    this.setState({invoice})
  }

  handleDetailsAmountChange = (index,field,value) => {
    let invoice = this.state.invoice

    invoice.details[index][field] = value

    if (field === "amount")
    {
      let vat = value * this.state.vatRate
      invoice.details[index].vat = vat
    }

    this.setState({invoice})
  }

  addDetailRow = () => {
    let invoice = this.state.invoice
    invoice.details.push({...blankDetails})
    this.setState({invoice})
  }

  deleteDetailRow = index => {
    let invoice = this.state.invoice
    invoice.details.splice(index,1)
    this.setState({invoice})
  }

  closeDialog = async () => {
    this.setState({invoice:null})
    this.props.close()
  }

  toggleInactiveClients = () => {
    let inactiveClients = !this.state.inactiveClients
    this.setState({inactiveClients})
    this.getClients()
  }

  render() {
    const { classes, open } = this.props

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} disableclose={true}>
      <DialogTitle id="form-dialog-title">
        {(this.state.invoice && this.state.invoice.invoicenumber) &&
          <TextField
            id={"invoicenumber"}
            name="invoicenumber"
            value={this.state.invoice.invoicenumber}
            onChange={this.handleInvoiceChange}
            label="Invoice number"
            size="medium"
            variant="outlined"
          />
        }

        {!(this.state.invoice && this.state.invoice.invoicenumber) &&
          <React.Fragment>Create Invoice</React.Fragment>
        }
      </DialogTitle>

      {this.state.invoice &&
        <DialogContent>
          <span style={{float:"right"}}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.inactiveClients}
                  onChange={this.toggleInactiveClients}
                  color="primary"
                />
              }
              label="Show inactive clients"
            />
          </span>

          <Grid container justify="space-around" style={{marginBottom:"30px"}}>
            
            <FormControl className={classes.formControl} style={{minWidth: 300}}>
              <InputLabel id="client_id-label">Client</InputLabel>
              <Select value={this.state.invoice.client_id} onChange={this.handleInvoiceChange} name="client_id" labelId="client_id-label">
                {this.state.clients.map(c => <MenuItem key={c.client_id} value={c.client_id}>{c.name}</MenuItem>)}
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                orientation="portrait"
                margin="normal"
                label="Invoice date"
                format="dd MMM yyyy"
                value={this.state.invoice.invoice_date}
                onChange={this.handleInvoiceDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {this.state.invoice && this.state.invoice.details.map((d,i) =>
            <div key={i} style={{marginBottom:"10px"}}>
              <Grid container justify="space-around">
                <TextField
                  id={i+"details"}
                  name="details"
                  value={d.details}
                  onChange={event => this.handleDetailsChange(event,i)}
                  label="Details"
                  size="medium"
                  multiline
                  rowsMax={8}
                  style={{width:"500px"}}
                  variant="outlined"
                />
                <CurrencyTextField
                  id={i+"amount"}
                  label="Net Amount"
                  value={d.amount}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event,value) => this.handleDetailsAmountChange(i,"amount",value)}
                  onBlur={(event,value) => this.handleDetailsAmountChange(i,"amount",value,event)}
                  style={{width:"150px"}}
                  variant="outlined"
                />
                <CurrencyTextField
                  id={i+"vat"}
                  label="VAT"
                  value={d.vat}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event,value) => this.handleDetailsAmountChange(i,"vat",value)}
                  onBlur={(event,value) => this.handleDetailsAmountChange(i,"vat",value,event)}
                  variant="outlined"
                  style={{width:"150px"}}
                />
                <CurrencyTextField
                  id={i+"grossAmount"}
                  label="Gross Amount"
                  value={d.amount + d.vat}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  disabled={true}
                  variant="outlined"
                  style={{width:"150px"}}
                />

                <IconButton onClick={event => this.deleteDetailRow(i)} color="secondary">
                  <DeleteIcon/>
                </IconButton>
              </Grid>
            </div>
            
          )}

            <div>
              <Grid container justify="space-around">
              <TextField
                  value="TOTAL"
                  size="medium"
                  style={{width:"500px"}}
                  disabled={true}
                  inputProps={{style: { textAlign: 'right' }}} 
                />
                <CurrencyTextField
                  value={this.state.invoice.details.reduce((a,b) => a + b.amount,0)}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  disabled={true}
                  style={{width:"150px"}}
                />
                <CurrencyTextField
                  value={this.state.invoice.details.reduce((a,b) => a + b.vat,0)}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  disabled={true}
                  style={{width:"150px"}}
                />
                <CurrencyTextField
                  value={this.state.invoice.details.reduce((a,b) => a + b.amount + b.vat,0)}
                  currencySymbol="£"
                  outputFormat="number"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  disabled={true}
                  style={{width:"150px"}}
                />
                <IconButton style={{visibility:"hidden"}}>
                  <DeleteIcon/>
                </IconButton>
              </Grid>
            </div>

        </DialogContent>
      }

      <DialogActions>
        {this.state.invoice && this.state.invoice.details &&
          <IconButton onClick={this.addDetailRow} color="primary">
            <AddIcon/>
          </IconButton>
        }
        
        <div style={{flex: '1 0 0'}} />
        <span className={classes.errorMessage}>{this.state.errorMessage}</span>
        <div style={{flex: '1 0 0'}} />

        <IconButton onClick={this.closeDialog} color="primary">
          <CloseIcon/>
        </IconButton>

        {this.state.invoice && this.state.invoice.details &&
          <IconButton onClick={this.submitInvoice} color="primary">
            <SaveIcon/>
          </IconButton>
        }
      </DialogActions>

      </Dialog>
    )
  }
}

export default withStyles(styles)(EditInvoiceForm)