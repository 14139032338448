import ValueFormatter from '../components/value-formatter'
import Moment from 'moment-timezone'

const numberSort = (num1,num2) => {
  return num1 - num2
}

const getGridConfig = () => {

  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "financialYear",
        headerName: "Financial Year",
        menuTabs : [],
        sortable: true,
        filter: true,
        width : 150,
        pinnedRowCellRenderer: 'customPinnedRowRenderer',
      },
      {
        field: "transaction_date",
        headerName: "Date",
        menuTabs : [],
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        sortable: false,
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['inRange','equals','empty'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        width : 120,
      },
      {
        field: "type_name",
        headerName: "Transaction",
        menuTabs : [],
        sortable: true,
        filter: true,
        width : 300,
      },
      {
        field: "amount_out",
        headerName: "Amount Out",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "amount_in",
        headerName: "Amount In",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "balance",
        headerName: "Balance",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
    ]
  }

  return gridconfig
}

export default getGridConfig