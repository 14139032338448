import React from 'react'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'

import Config from '../config'

const getGridConfig = (actionClickHandler) => {

  let userData = Config.getConfig('userData')

  return [
    {
      field: "name",
      headerName: "User",
      menuTabs : [],
      sortable: true,
      filter: true,
      width :300,
    },
    {
      field: "username",
      headerName: "username",
      menuTabs : [],
      sortable: false,
      filter: true,
      width : 200,
    },
    {
      field: "phone",
      headerName: "Phone",
      menuTabs : [],
      sortable: false,
      filter: false,
      width : 200,
    },
    {
      field: "email",
      headerName: "Email",
      menuTabs : [],
      sortable: false,
      filter: false,
      width : 300,
    },
    {
      field: "edit_right",
      headerName: "Edit",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "admin_right",
      headerName: "Admin",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "employee",
      headerName: "Employee",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "active",
      headerName: "Active",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "user_id",
      headerName: "Action",
      cellRenderer: 'actionCellRenderer',
      headerComponentFramework: (props) => {
        return userData.edit_right ? 
          (<Button onClick={event => actionClickHandler('new')} color="primary" startIcon={<AddIcon />}>new user</Button>) 
          : 
          (
          <div class="ag-cell-label-container">
          <div class="ag-header-cell-label">
              <span class="ag-header-cell-text"> </span>
          </div> 
        </div>)
      },
      cellRendererParams: item => {
        return {
          clicked: actionClickHandler,
          view : true,
        }
      },
      menuTabs : [],
      filter: false,
      width : 150,
    },
  ]
}

export default getGridConfig