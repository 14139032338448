import React from 'react'
import Moment from 'moment-timezone'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'

import Config from '../config'

const numberSort = (num1,num2) => {
  return num1 - num2
}


const getGridConfig = (actionClickHandler,openNewTransactionForm) => {

  let userData = Config.getConfig('userData')
  
  let gridconfig = {
   
    visibleRowBufferSize : 10,

    columnDefs : [
      {
        field: "transaction_date",
        headerName: "Date",
        menuTabs : [],
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['inRange','greaterThan'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
          pinnedRowCellRenderer: 'customPinnedRowRenderer',
        },
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        width : 130,
        sortable: true,
      },
      {
        field: "group_name",
        headerName: "Transaction Type",
        menuTabs : [],
        filter: true,
        width : 300,
        sortable: true,
        valueFormatter : item => item.value ? item.value + (item.data.type_name !== item.value ? (' : ' + item.data.type_name) : '') : '',
      },
      {
        field: "notes",
        headerName: "Details & Links",
        menuTabs : [],
        width : 400,
        cellRenderer: 'linksCellRenderer',
        cellRendererParams: item =>
        { return {
          clicked: actionClickHandler,
          view : true,
        }},
      },
      {
        field: "amount_in",
        headerName: "Income",
        menuTabs : [],
        filter: true,
        sortable: true,
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        comparator: numberSort,
        width : 140,
      },
      {
        field: "amount_out",
        headerName: "Outgoings",
        menuTabs : [],
        filter: true,
        sortable: true,
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value * -1),
        comparator: numberSort,
        width : 140,
      },
      {
        field: "vat",
        headerName: "VAT",
        menuTabs : [],
        filter: true,
        sortable: true,
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value * (item.data.amount_out > 0 ? -1 : 1)),
        comparator: numberSort,
        width : 140,
      },
      {
        field: "balance",
        headerName: "Balance",
        menuTabs : [],
        filter: true,
        sortable: true,
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        valueFormatter : item => isNaN(item.value) ? '' : ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        width : 140,
      },
      {
        field: "transaction_id",
        headerName: "",
        menuTabs : [],
        filter: false,
        sortable: false,
        cellRenderer: 'actionCellRenderer',
        width : 80,
        cellRendererParams: item =>
          { return {
            clicked: actionClickHandler,
            view : true,
        }},
        headerComponentFramework: (props) => {
          return userData.edit_right ? 
           (<Button onClick={openNewTransactionForm} color="primary" startIcon={<AddIcon />}>new</Button>) 
           :
           (
           <div class="ag-cell-label-container">
            <div class="ag-header-cell-label">
                <span class="ag-header-cell-text">Action</span>
            </div>
          </div>)
        },
      },
    ]
  }

  return gridconfig
}

export default getGridConfig