import ValueFormatter from '../components/value-formatter'

const numberSort = (num1,num2) => {
  return num1 - num2
}

const getGridConfig = () => {

  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "item_id",
        headerName: "ID",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        comparator: numberSort,
        filter: false,
        width : 90,
      },
      {
        field: "item_date",
        headerName: "Date",
        menuTabs : [],
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        sortable: false,
        width : 120,
      },
      {
        field: "vat_return_category",
        headerName: "Category",
        menuTabs : [],
        sortable: false,
        filter: false,
        width : 200,
      },
      {
        field: "detail",
        headerName: "Detail",
        menuTabs : [],
        sortable: false,
        filter: false,
        width : 320,
      },
      {
        field: "amount_in",
        headerName: "Net In",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        filter: true,
        width : 120,
      },
      {
        field: "vat_in",
        headerName: "VAT in",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        filter: true,
        width : 120,
      },
      {
        field: "amount_out",
        headerName: "Net Out",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        filter: true,
        width : 120,
      },
      {
        field: "vat_out",
        headerName: "VAT out",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        filter: true,
        width : 120,
      },

    ]
  }

  return gridconfig
}

export default getGridConfig