import Axios from 'axios'
import Config from './config'

import PAK from './package.alias.json'


const getGovGatewayHeaders = async () => {

  let timeZoneOffset = new Date().getTimezoneOffset
  let timezone = 'UTC+00:0' + (timeZoneOffset === -60 ? 1 :0)

  return {
    doNotTrack : navigator.doNotTrack ? true : false,
    userAgent : navigator.userAgent,
    plugins : Array.from(navigator.plugins).map(p => encodeURIComponent(p.name)).join(","),
    screenData :`width=${window.screen.width}&height=${window.screen.height}&scaling-factor=${window.devicePixelRatio}&colour-depth=${window.screen.colorDepth}`,
    timezone,
    windowSize :`width=${window.innerWidth}&height=${window.innerHeight}`,
    version : PAK.version,
    localIp : await findIP()
  }
}

const findIP = () => { // onNewIp - your listener function for new IPs

  return new Promise(async function(resolve,reject) {


    resolve("192.168.0.124") // This function no longer works in Safari

    window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;   //compatibility for firefox and chrome
    var pc = new RTCPeerConnection({iceServers:[]}), noop = function(){};      

    pc.createDataChannel("");    //create a bogus data channel

    pc.createOffer(pc.setLocalDescription.bind(pc), noop);    // create offer and set local description

    pc.onicecandidate = function(ice)
    {  //listen for candidate events
      if(!ice || !ice.candidate || !ice.candidate.candidate)  return;
        var myIP = ice.candidate.candidate.replace(/.* ([^ ]*\.local).*/,'$1')
        pc.onicecandidate = noop;

        resolve(myIP)
    }
  })
}



const callAccountsApi = async (pkg,fn,params) => {

  if (!params)
  {
    params = {}
  }

  params.govHeaders = await getGovGatewayHeaders()

  console.log("govHeaders",params.govHeaders)
  console.log("fn",fn)
  console.log("params",params)

  let options = {
    method : 'POST',
    url : `${Config.getConfig('adminApi')}request`,
    data : {
      pkg,
      fn,
      params
    },
    headers : {
      'x-api-key' : Config.getAPIKey(),
      'Content-type': 'application/json',
    },
  }

  try
  {
    let result = await Axios(options)

    console.log("result",result)

    return result.data
  }
  catch(err)
  {
    console.log(err)
    console.log("err.data",err.response.data)
    throw err
  }
}

export default callAccountsApi