import ValueFormatter from '../components/value-formatter'

const numberSort = (num1,num2) => {
  return num1 - num2
}

const getGridConfig = (actionClickHandler) => {

  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "financialYear",
        headerName: "Financial Year",
        menuTabs : [],
        sortable: true,
        filter: true,
        width : 150,
        pinnedRowCellRenderer: 'customPinnedRowRenderer',
      },
      {
        field: "name",
        headerName: "Person",
        menuTabs : [],
        sortable: true,
        filter: true,
        width : 300,
      },
      {
        field: "amount_out",
        headerName: "Amount",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "balance",
        headerName: "Total To date",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "user_id",
        headerName: "Action",
        cellRenderer: 'ActionCellRenderer',
        cellRendererParams: item => {
          return {
            clicked: actionClickHandler,
            view : true,
            print : false,
            download : false,
            email :false,
          }
        },
        menuTabs : [],
        filter: false,
        width : 250,
      },
    ]
  }

  return gridconfig
}

export default getGridConfig