const Moment = require('moment-timezone')

exports.formatDate = value => {
  let date = Moment(value)
  return date.isValid() ? date.format('DD MMM YYYY') : ""
}

exports.formatVATPeriod = value => {
  let date = Moment(value)
  return date.isValid() ? date.format('MMMM YYYY') : ""
}


exports.formatMoney = amount => {

  if (amount < 0 && amount > -0.01) {amount = 0}

  return new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount)
}

exports.formatMoneyNoDecimal = amount => {

  if (amount < 0 && amount > -0.01) {amount = 0}

  return new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits : 0,
    maximumFractionDigits : 0,
  }).format(amount)
}

exports.formatMoneyOrNullifNUll = amount => {

  if (amount === null) return ''

  if (amount < 0 && amount > -0.01) {amount = 0}

  return new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount)
}

exports.formatMoneyOrNull = amount => {

  if (amount < 0 && amount > -0.01) {amount = 0}

  return amount !== null && amount !== 0 ? new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount) : ''
}

exports.yesNo = value => {
  return value ? "yes" : "no"
}

exports.formatIncomeOrOutgoing = (inamount,outamount) => {

  let amount = inamount ? inamount : outamount * -1

  if (amount < 0 && amount > -0.01) {amount = 0}

  return amount !== 0 ? new Intl.NumberFormat('gb-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(amount) : ''
}