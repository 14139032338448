import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import cookie from 'react-cookies'

import {Dialog,DialogActions,DialogContent, TextField, Button} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Config from './config'
import logo from './images/smartix-logo.png'

const styles = theme => ({
  DialogContent : {
    padding : '20px 24px 24px',
    width : 300,
  },
  Logo : {
    marginLeft : 45,
    width : 200
  }
})

class Login extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {loginError:null}
  }

  componentDidMount() {
    this.props.setLoginState(false)
    cookie.remove('smartix-accounts-config',{expires:0, path: '/'})
    Config.deleteConfig('awsConfig')
    Config.deleteConfig('keys')
    Config.deleteConfig('userData')
  }

 onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.login()
    }
  }

  async login () {

    let data = {
      "username" : this.usernameFieldRef.value,
      "password" : this.passwordFieldRef.value
    }

    let options = {
      method : 'POST',
      url : `${Config.getConfig('adminApi')}login`,
      data : data,
      headers : {
        'Content-type': 'application/json',
      },
    }

    if (!this.usernameFieldRef.value || !this.passwordFieldRef.value){
      return
    }

    try
    {
      let result = await Axios(options)

      console.log("login result",result)

      if (result.status === 200)
      {
        Config.setConfig("awsConfig",result.data.awsConfig)
        Config.setConfig("keys",result.data.keys)
        Config.setConfig("userData",result.data.userData)

        this.setState({loginError:null})
        this.userIsLoggedIn(result.data)
      }
    }
    catch(err)
    {
      console.log(JSON.stringify(err,null,2))
      this.setState({loginError:"Incorrect login credentials"})
    }
  }

  userIsLoggedIn(config) {

    cookie.save('smartix-accounts-config', JSON.stringify(config), { path: '/' })

    Config.setConfig('awsConfig',config.awsConfig)
    Config.setConfig('keys',config.keys)
    Config.setConfig('userData',config.userData)

    this.props.setLoginState(true)
    this.props.history.push('/')
  }

  
  render() {

    const { classes } = this.props

    return (
      <Dialog
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.DialogContent}>

          <form>

          <img src={logo} className={classes.Logo} alt="Hazardous Frog logo"/>

          <TextField
            autoFocus
            id="username"
            label="Username"
            fullWidth
            inputRef={ref => this.usernameFieldRef = ref}
          />

          <TextField
            id="password"
            label="Password"
            fullWidth
            type="password"
            inputRef={ref => this.passwordFieldRef = ref}
            onKeyDown={this.onKeyDown}
          />            

          </form>

          <div style={{color:'red',paddingTop:'20px',fontStyle:'italic'}}>{this.state.loginError}</div>

        </DialogContent>

        <DialogActions>
          <Button onClick={event => this.login()} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Login))