import React, { Component }  from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import cookie from 'react-cookies'

import MainNav from './components/main-nav'
import DynamicComponent from './components/dynamic-component'

import './App.css'

import {getRoutes} from './routes'

import Config from './config'
import Login from './login'
import Logout from './logout'

import 'fontsource-roboto'

var routes = []

function isLoggedIn() {

  let cg = Config.getConfig('awsConfig')
  return cg ? true : false
}

const theme = createMuiTheme({
  overrides : {
    MuiAppBar : {
      root : {
        background: 'black',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        color: 'white'
      }
    },
    MuiMenu : {
      paper : {
        background: '#333',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        color: 'white',
      },
    },
    MuiMenuItem : {
      root : {
        '&:hover' : {
          background: '#000',
          color: 'yellow',
        }
      },
    },
  }
})


function checkLoginCookie() {

  let config = cookie.load('smartix-accounts-config')

  if (config !== undefined)
  {
    Config.setConfig('awsConfig', config.awsConfig)
    Config.setConfig('keys', config.keys)
    Config.setConfig('userData', config.userData)

    routes = getRoutes(config.userData)
  }
}

class App extends Component {

  constructor(props, context) {
    super(props, context)
    checkLoginCookie()
    this.state = {isLoggedIn:isLoggedIn(),searchTerm:""}
  }

  setLoginState(state) {
    this.setState({isLoggedIn:state})
    let userData = Config.getConfig('userData')
    routes = getRoutes(userData)
  }

  searchTermChanged(searchTerm) {
    this.setState({searchTerm})
  }

  render() {

    // Force logout
    if (this.state.isLoggedIn && (window.location.pathname === '/logout'))
    {
      return (
      <BrowserRouter>
        <Redirect to="/logout"/>
        <Route exact path="/logout">
          <MainNav routes={[]}  activeRoute={{name:"Logout"}} searchTermChanged={event => this.searchTermChanged(event)}/>
          <Logout/>
        </Route>
      </BrowserRouter>)
    }

    // Force login
    if (!this.state.isLoggedIn && (window.location.pathname !== '/login'))
    {
      return (
      <BrowserRouter>
        <Redirect to="/login"/>
        <Route exact path="/login">
          <MainNav routes={[]}  activeRoute={{name:"Login"}} searchTermChanged={event => this.searchTermChanged(event)}/>
          <Login setLoginState={event => this.setLoginState(event)}/>
        </Route>
      </BrowserRouter>)
    }
 
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <div>
            <Route exact path="/login">
              <MainNav routes={[]}  activeRoute={{name:"Login"}} searchTermChanged={event => this.searchTermChanged(event)}/>
              <Login setLoginState={event => this.setLoginState(event)}/>
            </Route>
    
            <Route exact path="/">
              <Redirect to="/invoices"/>
            </Route>

            {routes.map(route => { 
              return (
              <Route exact={route.exact} key={route.url} path={route.url} component={route.src}>
                <MainNav routes={routes} activeRoute={route} searchTermChanged={event => this.searchTermChanged(event)}/>
                <div className="App">
                  <DynamicComponent component={route.component} route={route} searchTerm={this.state.searchTerm}/>
                </div>
              </Route>
            )
          }
            )}
          </div>
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}

export default App
