import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Grid} from '@material-ui/core'

import Menu from './menu'
import MileageGrid from './mileage-grid'

import callAccountsApi from '../data-provider'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    textAlign : 'center'
  },
  yearNumber : {
    fontSize : '32px',
    marginTop : '40px !important',
    marginBottom : '10px !important',
  }
})

class Reports extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {years:[]}
  }
  
  componentDidMount() {
    this.getMileage()
  }

  getMileage = async () => {
    let years = await callAccountsApi("reports","getMileage")
    console.log(years)
    this.setState({years})
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Menu page="mileage"/>

        <Paper className={classes.paper}>
          <Grid container justify="space-around" direction="row">
            
            {Object.keys(this.state.years).map(y =>
              <Grid item>
                <Grid container justify="space-around" direction="column">
                  <Grid item><div className={classes.yearNumber}>{y}</div></Grid>
                  
                  <Grid item>
                    <MileageGrid mileage={this.state.years[y]} year={parseInt(y,10)}/>
                  </Grid>
                </Grid>
              </Grid>
            )}

            </Grid>
        </Paper>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Reports))