import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Button, TextField, ListSubheader, Dialog,DialogTitle,DialogContent,DialogActions, IconButton, Table, TableCell, TableRow, TableHead, FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment-timezone'

import PrintIcon from '@material-ui/icons/Print'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { ImLink as LinkIcon } from "react-icons/im"

import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import ValueFormatter from '../components/value-formatter'
import {DropzoneArea} from 'material-ui-dropzone'

import callAccountsApi from '../data-provider'

import Config from '../config'

import no_image from '../images/no_scan_image.jpg'

const Utils = require('../components/utils')
const S3 = require('../components/aws-s3')

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
})

class PurchaseDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {originalPurchase:{},purchase:{},edit:false,purchaseScan:null,purchaseScanType:null,mode:'view',newScanImage:null,newScanImageType:null,purchaseTypes:[]}
  }

  componentDidMount() {
    let userData = Config.getConfig('userData')
    let edit = userData.edit_right === 1
    this.setState({edit,mode:this.props.mode})
  }

  componentDidUpdate(prevProps) {
    let purchase = this.props.purchase
    let originalPurchase = {...this.props.purchase}

    if (this.props.mode !== prevProps.mode)
    {
      this.setState({mode:this.props.mode})
    }

    if ((purchase && this.props.purchase !== prevProps.purchase) || this.props.mode !== prevProps.mode)
    {
      this.setState({originalPurchase,purchase,purchaseScan:null,purchaseScanType:null,mode:this.props.mode,newScanImage:null,newScanImageType:null},() => {
        if (this.props.purchase.scan_image)
        {
          this.getPurchaseImage(this.props.purchase)
        }
        this.getVATRate()
        this.getTransactionTypes()
      })
    }
  }

  getTransactionTypes = async () => {

    let allTypes = await callAccountsApi("transactions","getTransactionTypes")

    let purchaseTypes = allTypes.filter(p => p.purchase)

    this.setState({purchaseTypes})
  }

  getPurchaseImage = async purchase => {
    let key = `purchases/${Moment(purchase.purchase_date).year()}/${Moment(purchase.purchase_date).month() +1}/${purchase.scan_image}`
     
    let scan = await S3.getFile('data.accounts.smartix.uk',key)

    if (scan)
    {
      let purchaseScanType = scan.ContentType
      let purchaseScanBuffer = Buffer.from(scan.Body)
      let blob = new Blob([purchaseScanBuffer], { type: purchaseScanType })
      let purchaseScan = URL.createObjectURL(blob)

      this.setState({purchaseScan,purchaseScanType,purchaseScanBuffer})
    }
  }

  startEditMode = () => {
    let mode = 'edit'
    this.setState({mode})
  }

  savePurchase = async () => {
    let purchase = this.state.purchase
    let originalPurchase = this.state.originalPurchase

    if (purchase.purchase_type === '')
    {
      this.setState({errorMessage:"Please choose purchase type before saving"})
      return
    }

    if (purchase.amount === 0)
    {
      this.setState({errorMessage:"Please set amount before saving"})
      return
    }

    purchase.purchase_id = await callAccountsApi("purchases","savePurchase",{purchase:this.state.purchase})

    // If we are creating a new statement, then scan_image will be undefined.pdf / jpg. We need to correct it.
    if (purchase.scan_image)
    {
      if (purchase.scan_image.split(".").shift() === "undefined")
      {
        purchase.scan_image = purchase.purchase_id + "." + purchase.scan_image.split(".").pop()
        // update our saved statement record with correct scan_image name
        await callAccountsApi("purchases","savePurchase",{purchase:this.state.purchase})
      }

      // If we change start_date year or account_id then we need to move scan image...
      let originalS3key = `purchases/${Moment(originalPurchase.purchase_date).year()}/${Moment(originalPurchase.purchase_date).month()+1}/${originalPurchase.scan_image}`
      let newS3key = `purchases/${Moment(purchase.purchase_date).year()}/${Moment(purchase.purchase_date).month()+1}/${purchase.scan_image}`

      if (originalS3key !== newS3key || this.state.newScanImage)
      {
        let image = this.state.newScanImage ? this.state.newScanImage : this.state.statementScanBuffer
        let type = this.state.newScanImageType ? this.state.newScanImageType : this.state.purchaseScanType
        
        if (image)
        {
          let result = await S3.putFile("data.accounts.smartix.uk",newS3key,image,type,originalS3key !== newS3key ? originalS3key : null)
          console.log("S3 result",result)
        }
      }
    }
    
    this.props.close(true,purchase)
  }

  deletePurchase = async () => {
    await callAccountsApi("purchases","deletePurchase",{purchase:this.state.purchase})

    if (this.state.purchaseScanBuffer)
    {
      let originalPurchase = this.state.originalPurchase
      let originalS3key = `purchases/${Moment(originalPurchase.purchase_date).year()}/${Moment(originalPurchase.purchase_date).month()+1}/${originalPurchase.scan_image}`

      await S3.deleteFile("data.accounts.smartix.uk",originalS3key)
    }

    this.props.close(true)
  }

  downloadPurchase = () => {
    let imageBuffer = this.state.newScanImage ? this.state.newScanImage : this.state.purchaseScanBuffer
    let type = this.state.newScanImageType ? this.state.newScanImageType : this.state.purchaseScanType
    let extension = this.state.purchase.scan_image.split(".").pop()

    let filename = `${this.state.purchase.detail}-${Moment(this.state.purchase.purchase_date).format("DD-MMM-YYYY")}.${extension}`

    var blob = new Blob([imageBuffer], { type })
    var url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = filename

    a.click()
  }

  printPurchase = () => {
    var imageiframe = document.getElementById('imageiframe')
    imageiframe.contentWindow.print()
  }

  handleChange = (field,value) => {
    let purchase = this.state.purchase
    purchase[field] = value

    if (field === "amount")
    {
      purchase.vat = 0
    }

    if (field === "purchase_date")
    {
      this.getVATRate(value)
    }

    this.setState({purchase})
  }

  getVATRate = async (date = this.state.purchase.purchase_date) => {
    let vatRate = await callAccountsApi("invoices","getVATRate",{date})
    this.setState({vatRate},this.calculateVAT)
  }

  calculateVAT = () => {
    if (this.state.mode === "edit")
    {
      let purchase = this.state.purchase

      let vat = purchase.amount - (purchase.amount / (1 + this.state.vatRate))
      purchase.vat = vat

      this.setState({purchase})
    }
  }

  fetchPurchase = async file => {
    if (file)
    {
      let filetype = file.name.split(".").pop().toLowerCase()
      let filedata = await Utils.fileReaderPromise(file)

      let base64data = Utils.extractBase64FromFileData(filedata)
      let bufferData = Utils.base64ToArrayBuffer(base64data)

      let purchase = this.state.purchase

      // Will be undefined for a new purchase...
      let filename = `${purchase.purchase_id}.${filetype}`

      purchase.scan_image = filename

      let newScanImage = bufferData
      let newScanImageType = file.type

      this.setState({purchase,newScanImage,newScanImageType})

      let blob = new Blob([newScanImage], { type: newScanImageType })

      let purchaseScan = URL.createObjectURL(blob)

      this.setState({purchaseScan})
    }
   }


   getPurchaseTypeMenuOptions = () => {

    let options = []
    let lastGroupId = 0

    this.state.purchaseTypes.forEach(p => {
      if (p.group_id !== lastGroupId)
      {
        lastGroupId = p.group_id
      options.push(<ListSubheader key={p.group_name} className="selectGroupHeader">{p.group_name}</ListSubheader>)
      }
      options.push(<MenuItem key={p.type_id} value={p.type_id} className="indentedSelectOption">{p.type_name}</MenuItem>)
    })

    return options
   }

  render() {
    const { classes, open, close } = this.props
    const purchase = this.state.purchase

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">
        {this.state.purchase.purchase_id && 
          <div>Purchase ID : {this.state.purchase.purchase_id} ({Moment(purchase.purchase_date).year()}/{Moment(purchase.purchase_date).month()+1}/{purchase.scan_image})</div>
        }

        {!this.state.purchase.purchase_id && 
          <div>Create purchase</div>
        }
      </DialogTitle>
      <DialogContent>

        <Table className={classes.table}>
            {
              {
                "view":
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHeader">Transaction Type</TableCell>
                    <TableCell>{purchase.group_name} : {purchase.type_name}</TableCell>
                    <TableCell className="tableHeader">Purchase Date</TableCell>
                    <TableCell>{ValueFormatter.formatDate(purchase.purchase_date)}</TableCell>
                    <TableCell className="tableHeader">Amount</TableCell>
                    <TableCell>{ValueFormatter.formatMoney(purchase.amount)}</TableCell>
                    <TableCell className="tableHeader">VAT</TableCell>
                    <TableCell>{ValueFormatter.formatMoney(purchase.vat)}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="tableHeader">Detail</TableCell>
                    <TableCell colSpan={5}>{purchase.detail}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="tableHeader">Comment</TableCell>
                    <TableCell colSpan={5}>{purchase.comment}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className="tableHeader">Bank Account</TableCell>
                    <TableCell>{purchase.bankAccountName}</TableCell>
                    <TableCell className="tableHeader">Transaction Date</TableCell>
                    <TableCell>{ValueFormatter.formatDate(purchase.transaction_date)}</TableCell>
                  </TableRow>
                </TableHead>,

                "edit":
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControl className={classes.formControl} style={{minWidth: 250}}>
                        <InputLabel htmlFor="purchase_type">Purchase type</InputLabel>
                        <Select value={this.state.purchase.purchase_type} onChange={(event,item) => this.handleChange("purchase_type",item.props.value,event)} name="purchase_type">
                          {this.getPurchaseTypeMenuOptions()}
                        </Select>
                      </FormControl>
                    </TableCell>
                    
                    <TableCell>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="inline"
                          orientation="portrait"
                          margin="normal"
                          label="Purchase Date"
                          format="dd MMM yyyy"
                          value={this.state.purchase.purchase_date}
                          onChange={date => this.handleChange("purchase_date",date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk={true}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                    <TableCell>
                      <CurrencyTextField
                        id="amount"
                        label="Gross Purchase Amount"
                        value={this.state.purchase.amount}
                        currencySymbol="£"
                        outputFormat="number"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        onChange={(event,value) => this.handleChange("amount",value,event)}
                        onBlur={(event,value) => this.handleChange("amount",value,event)}
                        inputProps={{style:{color:"red"}}}
                        variant="outlined"
                        minimumValue="0"
                      />
                    </TableCell>

                    <TableCell>
                      <CurrencyTextField
                        id="vat"
                        label="VAT"
                        value={this.state.purchase.vat}
                        currencySymbol="£"
                        outputFormat="number"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        onChange={(event,value) => this.handleChange("vat",value,event)}
                        onBlur={(event,value) => this.handleChange("vat",value,event)}
                        variant="outlined"
                        minimumValue="0"
                        style={{width:"120px"}}
                      />
                      <Button variant="contained" style={{marginTop:'10px',marginLeft:'10px'}} onClick={this.calculateVAT} color={this.state.purchase.vat === 0 ? "primary" : "default"}>Calc</Button>

                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={4}>
                      <TextField
                        id="detail"
                        name="detail"
                        label="Detail"
                        fullWidth
                        defaultValue={this.state.purchase.detail}
                        variant="outlined"
                        onChange={event => this.handleChange("detail",event.target.value)}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={4}>
                      <TextField
                        id="comment"
                        name="comment"
                        label="Comment"
                        defaultValue={this.state.purchase.comment}
                        variant="outlined"
                        onChange={event => this.handleChange("comment",event.target.value)}
                        multiline
                        fullWidth
                        rows={4}
                      />
                    </TableCell>
                  </TableRow>

                </TableHead>
              }[this.state.mode]
            }
          
        </Table>

        {this.state.purchaseScan &&
          <iframe style={{width:'100%',height:'500px',border:'none'}} src={this.state.purchaseScan} aria-label="Dropzone" id="imageiframe" title="scan_image"/>
        }

        {!this.state.purchaseScan &&
          {
            "view":
            <iframe style={{width:'100%',height:'500px',border:'none'}} src={no_image} aria-label="Dropzone" id="imageiframe"  title="scan_image"/>,
            "edit":
            <DropzoneArea
              acceptedFiles={['image/*','application/pdf']}
              clearOnUnmount={true}
              maxFileSize={5000000}
              onChange={files => this.fetchPurchase(files[0])}
              showPreviews={false}
              showPreviewsInDropzone={false}
              showAlerts={false}
              filesLimit={1}
              dropzoneClass={"uploaderDropZoneLarge"}
            />
          }[this.state.mode]
        }

      </DialogContent>

      <DialogActions>
        {this.state.purchase &&
          <React.Fragment>
            {
              this.state.edit && 
              {
                "view":
                <IconButton onClick={this.startEditMode} color="primary">
                  <EditIcon/>
                </IconButton>,
                "edit":
                <React.Fragment>
                  {this.state.purchase.purchase_id && 
                    <IconButton onClick={this.deletePurchase} color="secondary">
                      <DeleteIcon/>
                    </IconButton>
                  }
                  {this.state.purchaseScan &&
                    <DropzoneArea
                    acceptedFiles={['image/*','application/pdf']}
                    clearOnUnmount={true}
                    maxFileSize={5000000}
                    onChange={files => this.fetchPurchase(files[0])}
                    showPreviews={false}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    filesLimit={1}
                    dropzoneClass={"uploaderDropZone"}
                    />
                  }
                </React.Fragment>
              }[this.state.mode]
            }

            <div style={{flex: '1 0 0'}} />
              <span className={classes.errorMessage}>{this.state.errorMessage}</span>
            <div style={{flex: '1 0 0'}} />

            {
              {
                "view" :
                <React.Fragment>
                  <IconButton onClick={this.downloadPurchase} color="default" disabled={this.state.purchaseScan === null}>
                    <DownloadIcon/>
                  </IconButton>
                  <IconButton onClick={this.printPurchase} color="default" disabled={this.state.purchaseScan === null}>
                    <PrintIcon/>
                  </IconButton>
                  {this.props.link &&
                    <IconButton onClick={this.gotoPurchase} color="default" disabled={this.state.purchase.transaction_id === null && this.state.purchase.expense_id === null}>
                      <LinkIcon/>
                    </IconButton>
                  }
                </React.Fragment>,
                "edit" :
                  <IconButton onClick={this.savePurchase} color="primary">
                    <SaveIcon/>
                  </IconButton>
              }[this.state.mode]
            }

            <IconButton onClick={close} color="primary">
              <CloseIcon/>
            </IconButton>
          </React.Fragment>
        }
      </DialogActions>
    </Dialog>
  )
  }
}

export default withStyles(styles)(PurchaseDetail)