import React from 'react'
import Moment from 'moment-timezone'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'
import Config from '../config'

const numberSort = (num1,num2) => {
  return num1 - num2
}

const getGridConfig = (actionClickHandler) => {

  let userData = Config.getConfig('userData')
  
  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "financialYear",
        headerName: "Financial Year",
        menuTabs : [],
        sortable: true,
        filter: true,
        width :130,
        valueFormatter : item => `${item.value} - ${item.value + 1}`,
      },
      {
        field: "journey_date",
        headerName: "Date",
        menuTabs : [],
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['inRange','equals','empty'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        width : 120,
      },
      {
        field: "name",
        headerName: "Person",
        menuTabs : [],
        sortable: true,
        filter: true,
        width :120,
      },
      {
        field: "journey",
        headerName: "Journey",
        menuTabs : [],
        sortable: false,
        filter: true,
        width : 500,
      },
      {
        field: "miles",
        headerName: "Miles",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "amount",
        headerName: "Amount due",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "cumulativeMiles",
        headerName: "Miles financial year",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: false,
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "mileage_id",
        headerName: "Action",
        cellRenderer: 'ActionCellRenderer',
        headerComponentFramework: (props) => {
          return userData.edit_right ? 
           (<Button onClick={event => actionClickHandler('new')} color="primary" startIcon={<AddIcon />}>new journey</Button>) 
           : 
           (
           <div class="ag-cell-label-container">
            <div class="ag-header-cell-label">
                <span class="ag-header-cell-text">Action</span>
            </div>
          </div>)
        },
        cellRendererParams: item => {
          return {
            clicked: actionClickHandler,
            view : true,
            print : false,
            download : false,
            email :false,
          }
        },
        menuTabs : [],
        filter: false,
        width : 150,
      },
    ]
  }

  return gridconfig
}

export default getGridConfig