import React from 'react'
import Moment from 'moment-timezone'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'
import Config from '../config'

const numberSort = (num1,num2) => {
  return num1 - num2
}

const getGridConfig = (openNewInvoiceForm,actionClickHandler) => {

  let userData = Config.getConfig('userData')

  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "invoicenumber",
        headerName: "Invoice Number",
        menuTabs : [],
        filter: false,
        width : 150,
        pinnedRowCellRenderer: 'customPinnedRowRenderer',
        sortable: true,
      },
      {
        field: "invoice_date",
        headerName: "Date Raised",
        menuTabs : [],
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['inRange','equals','empty'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        width : 150,
      },
      {
        field: "transaction_date",
        headerName: "Date Paid",
        menuTabs : [],
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['inRange','equals','empty'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        width : 150,
      },
      {
        field: "client",
        headerName: "Client",
        menuTabs : [],
        filter: true,
        sortable: true,
        width : 350,
      },
      {
        field: "amount",
        headerName: "Net",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: true,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "vat",
        headerName: "VAT",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: true,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        comparator: numberSort,
        filter: false,
        width : 150,
      },
      {
        field: "grossAmount",
        headerName: "Gross",
        headerClass : "rightAlignHeader",
        cellClass : 'numberGridCell',
        menuTabs : [],
        sortable: true,
        comparator: numberSort,
        valueFormatter : item => ValueFormatter.formatMoney(item.value),
        filter: false,
        width : 150,
      },
      {
        field: "invoice_id",
        headerName: "Action",
        headerComponentFramework: (props) => {
          return userData.edit_right ? 
           (<Button onClick={openNewInvoiceForm} color="primary" startIcon={<AddIcon />}>new invoice</Button>) 
           : 
           (
           <div class="ag-cell-label-container">
            <div class="ag-header-cell-label">
                <span class="ag-header-cell-text">Action</span>
            </div>
          </div>)
        },
        cellRenderer: 'invoiceActionCellRenderer',
        cellRendererParams: item => {
          return {
            clicked: actionClickHandler,
            view : true,
            print : true,
            download : true,
            email :true,
            link : item.data.transaction_id
          }
        },
        menuTabs : [],
        filter: false,
        width : 250,
      },
    ]
  }

  return gridconfig
}

export default getGridConfig