export const fileReaderPromise = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function(e) {
      resolve(e.target.result)
    }

    reader.onerror = function(e) {
      reject(e)
    }

    reader.readAsDataURL(file)
  })
}


export const extractBase64FromFileData = dataString => {
  return dataString.replace(/data:[^;]*;base64,/,'')
}
 
export const base64ToArrayBuffer = base64 => {
  var binary_string = window.atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}