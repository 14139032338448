import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogTitle,DialogContent,DialogActions, IconButton, Table, TableCell, TableRow, TableHead, FormControl,FormControlLabel,InputLabel,Select,MenuItem,Switch} from '@material-ui/core'
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import Moment from 'moment-timezone'

import PrintIcon from '@material-ui/icons/Print'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

import ValueFormatter from '../components/value-formatter'
import {DropzoneArea} from 'material-ui-dropzone'

import callAccountsApi from '../data-provider'

import Config from '../config'
import no_image from '../images/no_scan_image.jpg'

const S3 = require('../components/aws-s3')
const Utils = require('../components/utils')

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
})

class StatementDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {originalStatement:{},statement:{},edit:false,statementScan:null,statementScanType:null,mode:'view',accounts:[],inactiveAccounts:false,newScanImage:null,newScanImageType:null}
  }

  componentDidMount() {
    let userData = Config.getConfig('userData')
    let edit = userData.edit_right === 1
    this.setState({edit})
  }

  componentDidUpdate(prevProps) {
    let statement = this.props.statement
    let originalStatement = {...this.props.statement}

    if ((statement && this.props.statement !== prevProps.statement) || this.props.mode !== prevProps.mode)
    {
      this.setState({originalStatement,statement,statementScan:null,statementScanType:null,mode:this.props.mode,accounts:[],inactiveAccounts:false,newScanImage:null,newScanImageType:null},() => {
        if (this.props.statement.scan_image)
        {
          this.getStatementImage(this.props.statement)
        }

        this.getAccounts()
      })
    }
  }
  
  getAccounts = async () => {
    let accounts = await callAccountsApi("accounts","getAccounts")
    accounts = accounts.filter(a => !a.hide)
    this.setState({accounts})
  }

  getStatementImage = async statement => {
    let key = `bank_statements/${statement.account_id}/${Moment(statement.start_date).year()}/${statement.scan_image}`
     
    let scan = await S3.getFile('data.accounts.smartix.uk',key)

    if (scan)
    {
      let statementScanType = scan.ContentType
      let statementScanBuffer = Buffer.from(scan.Body)
      let blob = new Blob([statementScanBuffer], { type: statementScanType })
      let statementScan = URL.createObjectURL(blob)

      this.setState({statementScan,statementScanType,statementScanBuffer})
    }
  }

  startEditMode = () => {
    let mode = 'edit'
    this.setState({mode})
  }

  saveStatement = async () => {
    let statement = this.state.statement
    let originalStatement = this.state.originalStatement

    if (statement.account_id === 0 || !statement.scan_image)
    {
      this.setState({errorMessage:"Please select an account and upload scan before saving"})
      return
    }

    statement.statement_id = await callAccountsApi("statements","saveStatement",{statement:this.state.statement})

    // If we are creating a new statement, then scan_image will be undefined.pdf / jpg. We need to correct it.
    if (statement.scan_image.split(".").shift() === "undefined")
    {
      statement.scan_image = statement.statement_id + "." + statement.scan_image.split(".").pop()
      // update our saved statement record with correct scan_image name
      await callAccountsApi("statements","saveStatement",{statement:this.state.statement})
    }

    // If we change start_date year or account_id then we need to move scan image...
    let originalS3key = `bank_statements/${originalStatement.account_id}/${Moment(originalStatement.start_date).year()}/${originalStatement.scan_image}`
    let newS3key = `bank_statements/${statement.account_id}/${Moment(statement.start_date).year()}/${statement.scan_image}`

    if (originalS3key !== newS3key || this.state.newScanImage)
    {
      let image = this.state.newScanImage ? this.state.newScanImage : this.state.statementScanBuffer
      let type = this.state.newScanImageType ? this.state.newScanImageType : this.state.statementScanType
       
      if (image)
      {
        await S3.putFile("data.accounts.smartix.uk",newS3key,image,type,originalS3key !== newS3key ? originalS3key : null)
      }
    }

    this.props.close(true)
  }

  deleteStatement = async () => {
    await callAccountsApi("statements","deleteStatement",{statement:this.state.statement})

    if (this.state.statementScanBuffer)
    {
      let originalStatement = this.state.originalStatement
      let originalS3key = `bank_statements/${originalStatement.account_id}/${Moment(originalStatement.start_date).year()}/${originalStatement.scan_image}`

      await S3.deleteFile("data.accounts.smartix.uk",originalS3key)
    }

    this.props.close(true)
  }

  downloadStatement = () => {
    let imageBuffer = this.state.newScanImage ? this.state.newScanImage : this.state.statementScanBuffer
    let type = this.state.newScanImageType ? this.state.newScanImageType : this.state.statementScanType
    let extension = this.state.statement.scan_image.split(".").pop()

    let filename = `${this.state.statement.name}-${Moment(this.state.statement.start_date).format("DD-MMM-YYYY")}.${extension}`

    var blob = new Blob([imageBuffer], { type })
    var url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = filename

    a.click()
  }

  printStatement = () => {
    var imageiframe = document.getElementById('imageiframe')
    imageiframe.contentWindow.print()
  }

  handleStatementDateChange = (field,date) => {
    let statement = this.state.statement
    statement[field] = date
    this.setState({statement})
  }

  fetchStatement = async file => {
    if (file)
    {
      let filetype = file.name.split(".").pop().toLowerCase()
      let filedata = await Utils.fileReaderPromise(file)

      let base64data = Utils.extractBase64FromFileData(filedata)
      let bufferData = Utils.base64ToArrayBuffer(base64data)

      let statement = this.state.statement

      let filename = `${statement.statement_id}.${filetype}`

      statement.scan_image = filename

      let newScanImage = bufferData
      let newScanImageType = file.type

      this.setState({statement,newScanImage,newScanImageType})

      let blob = new Blob([bufferData], { type: file.type })
      let statementScan = URL.createObjectURL(blob)

      this.setState({statementScan})
    }
   }

   handleAccountChange = event => {
     let statement = this.state.statement
     statement[event.target.name] = event.target.value
     statement.name = this.getAccountName(statement.statement_id)
     this.setState({statement})
   }

   getAccountName = id => {
     for (let a=0;a<this.state.accounts.length;a++)
     {
       if (this.state.accounts[a].account_id === id)
       {
         return this.state.accounts[a].name
       }
     }
   }

   toggleInactiveAccounts = () => {
     let inactiveAccounts = !this.state.inactiveAccounts
     this.setState({inactiveAccounts})
   }

  render() {
    const { classes, open, close } = this.props
    const statement = this.state.statement

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">
        {this.state.statement.statement_id && 
          <div>Statement ID : {this.state.statement.statement_id} (bank_statements/{statement.account_id}/{Moment(statement.start_date).year()}/{statement.scan_image})</div>
        }

        {!this.state.statement.statement_id && 
          <div>Create statement</div>
        }
      </DialogTitle>
      <DialogContent>

          <span style={{float:"right"}}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.inactiveAccounts}
                  onChange={this.toggleInactiveAccounts}
                  color="primary"
                />
              }
              label="Show inactive accounts"
            />
          </span>

        <Table className={classes.table}>
          <TableHead>
            {
              {
                "view":
                <TableRow>
                  <TableCell className="tableHeader">Account Name</TableCell>
                  <TableCell>{statement.name}</TableCell>
                  <TableCell className="tableHeader">From</TableCell>
                  <TableCell>{ValueFormatter.formatDate(statement.start_date)}</TableCell>
                  <TableCell className="tableHeader">To</TableCell>
                  <TableCell>{ValueFormatter.formatDate(statement.end_date)}</TableCell>
                </TableRow>,

                "edit":
                <TableRow>
                  <TableCell style={{width:"33%"}}>
                  <FormControl className={classes.formControl} style={{minWidth: 200}}>
                    <InputLabel id="user_id-label">Account Name</InputLabel>
                    <Select value={this.state.statement.account_id ? this.state.statement.account_id : ""} onChange={this.handleAccountChange} name="account_id" labelId="account_id-label">
                      {this.state.accounts.filter(a => a.active || this.state.inactiveAccounts).map(a => <MenuItem key={a.account_id} value={a.account_id}>{a.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  </TableCell>
                  <TableCell style={{width:"33%"}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        orientation="portrait"
                        margin="normal"
                        label="Start Date"
                        format="dd MMM yyyy"
                        value={this.state.statement.start_date}
                        onChange={date => this.handleStatementDateChange("start_date",date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell style={{width:"33%"}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        orientation="portrait"
                        margin="normal"
                        label="End Date"
                        format="dd MMM yyyy"
                        value={this.state.statement.end_date}
                        onChange={date => this.handleStatementDateChange("end_date",date)}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                </TableRow>
              }[this.state.mode]
            }
          </TableHead>
        </Table>

        {this.state.statementScan &&
          <iframe style={{width:'100%',height:'500px',border:'none'}} src={this.state.statementScan} aria-label="Dropzone" id="imageiframe" title="scan_image"/>
        }

        {!this.state.statementScan &&
          {
            "view":
            <iframe style={{width:'100%',height:'500px',border:'none'}} src={no_image} aria-label="Dropzone" id="imageiframe"  title="scan_image"/>,
            "edit":
            <DropzoneArea
            acceptedFiles={['image/*','application/pdf']}
            clearOnUnmount={true}
            maxFileSize={5000000}
            onChange={files => this.fetchStatement(files[0])}
            showPreviews={false}
            showPreviewsInDropzone={false}
            showAlerts={false}
            filesLimit={1}
            dropzoneClass={"uploaderDropZoneLarge"}
            />
          }[this.state.mode]
        }

      </DialogContent>

      <DialogActions>
        {this.state.statement &&
          <React.Fragment>
            {
              this.state.edit && 
              {
                "view":
                <IconButton onClick={this.startEditMode} color="primary">
                  <EditIcon/>
                </IconButton>,
                "edit":
                <React.Fragment>
                  {this.state.statement.statement_id && 
                    <IconButton onClick={this.deleteStatement} color="secondary">
                      <DeleteIcon/>
                    </IconButton>
                  }
                  {this.state.statementScan &&
                    <DropzoneArea
                    acceptedFiles={['image/*','application/pdf']}
                    clearOnUnmount={true}
                    maxFileSize={5000000}
                    onChange={files => this.fetchStatement(files[0])}
                    showPreviews={false}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    filesLimit={1}
                    dropzoneClass={"uploaderDropZone"}
                    />
                  }
                </React.Fragment>
              }[this.state.mode]
            }

            <div style={{flex: '1 0 0'}} />
              <span className={classes.errorMessage}>{this.state.errorMessage}</span>
            <div style={{flex: '1 0 0'}} />

            {
              {
                "view" :
                <React.Fragment>
                  <IconButton onClick={this.downloadStatement} color="default" disabled={this.state.statementScan === null}>
                    <DownloadIcon/>
                  </IconButton>
                  <IconButton onClick={this.printStatement} color="default" disabled={this.state.statementScan === null}>
                    <PrintIcon/>
                  </IconButton>
                </React.Fragment>,
                "edit" :
                  <IconButton onClick={this.saveStatement} color="primary">
                    <SaveIcon/>
                  </IconButton>
              }[this.state.mode]
            }

            <IconButton onClick={close} color="primary">
              <CloseIcon/>
            </IconButton>
          </React.Fragment>
        }
      </DialogActions>
    </Dialog>
  )
  }
}

export default withStyles(styles)(StatementDetail)