import Moment from 'moment-timezone'
import React, { Component } from 'react'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ValueFormatter from '../components/value-formatter'

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    invoice_date :null,
    transaction_date :null,    
    client:"TOTALS",
    amount : 0,
    vat : 0,
  }
  
  gridApi.forEachNode(row => {
    result.amount += row.data.amount ? parseFloat(row.data.amount) : 0
    result.vat += row.data.vat ? parseFloat(row.data.vat) : 0
  })

  result.amount = result.amount.toFixed(2)
  result.vat = result.vat.toFixed(2)

  return [result]
}

const numberSort = (num1,num2) => {
  return num1 - num2
}

class BankAccountGrid extends Component {

  onGridReady = params => {
    params.api.setPinnedBottomRowData(createTotalPinnedData(params.api))
  }
   
  dateCellClass = date => {

    if (Moment(date).year() !== this.props.year)
    {
      return "wrongYear"
    }
  }

  render() {

    return (
      <div className="ag-theme-alpine" style={ {width: '100%'} }>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.invoices}
          onRowDoubleClicked={item => this.props.actionClickHandler("view",item.data)}
          domLayout='autoHeight'
          columnDefs={[
            {
              field: "invoicenumber",
              headerName: "Invoice Number",
              cellClass : 'textGridCell',
              menuTabs : [],
              sortable: false,
              comparator: numberSort,
              filter: false,
              width : 300,
            },
            {
              field: "client",
              headerName: "Client",
              cellClass : 'textGridCell',
              menuTabs : [],
              sortable: false,
              comparator: numberSort,
              filter: false,
              width : 300,
            },
            {
              field: "invoice_date",
              headerName: "Date Raised",
              menuTabs : [],
              valueFormatter : item => ValueFormatter.formatDate(item.value),
              cellClass : item => this.dateCellClass(item.value),
              sortable: true,
              filter: 'agDateColumnFilter',
              filterParams : {
                filterOptions : ['inRange','equals','empty'],
                suppressAndOrCondition : true,
                inRangeInclusive : true,
                comparator : function (filter, value) {
                  let filterMoment = Moment(filter)
                  let valueMoment = Moment(value).tz('Europe/London')
        
                  if (valueMoment.isSame(filterMoment,'day')) return 0
                  if (valueMoment.isBefore(filterMoment,'day')) return -1
                  if (valueMoment.isAfter(filterMoment,'day')) return 1
                },
              },
              width : 150,
            },
            {
              field: "transaction_date",
              headerName: "Date Paid",
              menuTabs : [],
              valueFormatter : item => ValueFormatter.formatDate(item.value),
              cellClass : item => this.dateCellClass(item.value),
              sortable: true,
              filter: 'agDateColumnFilter',
              filterParams : {
                filterOptions : ['inRange','equals','empty'],
                suppressAndOrCondition : true,
                inRangeInclusive : true,
                comparator : function (filter, value) {
                  let filterMoment = Moment(filter)
                  let valueMoment = Moment(value).tz('Europe/London')
        
                  if (valueMoment.isSame(filterMoment,'day')) return 0
                  if (valueMoment.isBefore(filterMoment,'day')) return -1
                  if (valueMoment.isAfter(filterMoment,'day')) return 1
                },
              },
              width : 150,
            },
            {
              field: "amount",
              headerName: "Amount",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 120,
            },
            {
              field: "vat",
              headerName: "VAT",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 120,
            },
          ]}
          components = {{
            customPinnedRowRenderer: CustomPinnedRowRenderer,
          }}
          >
        </AgGridReact>
      </div>
    )
  }
}

export default (BankAccountGrid)