import Moment from 'moment-timezone'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {ListSubheader, Dialog,DialogTitle,DialogContent,DialogActions, TextField, ListItemText, Checkbox, Input, Button, IconButton, Table, TableCell, TableRow, TableHead, FormControl,FormControlLabel,InputLabel,Select,MenuItem,Switch} from '@material-ui/core'
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { MdClose as CloseIcon, MdEdit as EditIcon, MdSave as SaveIcon, MdDelete as DeleteIcon, MdFormatListBulleted as StatementIcon} from 'react-icons/md'

import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import ValueFormatter from '../components/value-formatter'

import callAccountsApi from '../data-provider'

import PurchaseDetail from '../purchases/purchase-detail'
import StatementDetail from '../statements/statement-detail'

import Config from '../config'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
  income: {
    color:"green"
  },
  outgoing: {
    color:"red"
  },
})

class TransactionDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {errorMessage:"",linkedAccountId:null,originalStatement:{},transaction:{},edit:false,mode:'view',accounts:[],inactiveAccounts:false,purchases:[],invoices:[],allPurchaseTypes:false,allInvoices:false,vatRate:0,openPurchaseForm:false,emptyPurchase:{},openStatement:false,vatreturns:[],selectedVATReturn:null}
  }

  componentDidMount() {
    let userData = Config.getConfig('userData')
    let edit = userData.edit_right === 1
    this.setState({edit})
  }

  componentDidUpdate(prevProps) {
    let transaction = this.props.transaction
    let originalTransaction = {...this.props.transaction}

    if ((transaction && this.props.transaction !== prevProps.transaction) || this.props.mode !== prevProps.mode)
    {
      this.setState({originalTransaction,transaction,mode:this.props.mode,accounts:[],inactiveAccounts:false,errorMessage:"",openStatement:false},() => {
        this.getAccounts()
        this.getVATRate()

        if (this.isPurchase(this.state.transaction.transaction_type_id))
        {
          this.getPossiblePurchases()
        }

        if (this.isInvoice(this.state.transaction.transaction_type_id))
        {
          this.getPossibleInvoices()
        }

        if (this.isVatReturn(this.state.transaction.transaction_type_id))
        {
          this.getPossibleVATReturns()
        }
      })
    }
  }

  getPossiblePurchases = async () => {
    let purchase_ids = this.state.transaction.purchases ? this.state.transaction.purchases.map(p => p.id) : []

    let params = {
      purchase_ids
    }

    if (!this.state.allPurchaseTypes)
    {
      params.purchase_type = this.state.transaction.transaction_type_id
    }

    let purchases = await callAccountsApi("purchases","getUnassignedPurchases",params)

    this.setState({purchases})
  }

  getPossibleInvoices = async () => {
    let invoice_ids = this.state.transaction.invoices ? this.state.transaction.invoices.map(p => p.id) : []

    let params = invoice_ids.length? {invoice_ids} : {}

    let invoices = this.state.allInvoices ? await callAccountsApi("invoices","getInvoices") : await callAccountsApi("invoices","getUnassignedInvoices",params)

    this.setState({invoices})
  }

  getPossibleVATReturns = async () => {
    let vat_ids = this.state.transaction.vatreturn ? this.state.transaction.vatreturn.map(p => p.id) : []

    let params = vat_ids.length? {vat_ids} : {}

    let vatreturns = await callAccountsApi("vat","getUnassignedVATReturns",params)

    this.setState({vatreturns})
  }
  
  getAccounts = async () => {
    let accounts = await callAccountsApi("accounts","getAccounts")
    accounts = accounts.filter(a => !a.hide)
    this.setState({accounts})
  }

  getAccountName = account_id => {
    this.state.accounts.forEach(a => {
      if (a.account_id === account_id)
      {
        return a.name
      }
    })
  }

  startEditMode = () => {
    let mode = 'edit'
    this.setState({mode})
  }

  saveTransaction = async () => {
    let transaction = {...this.state.transaction}
    
    if (!transaction.transaction_type_id)
    {
      this.setState({errorMessage:"Please choose transaction type"})
      return
    }

    if (this.isPurchase(transaction.transaction_type_id) && !transaction.purchases.length)
    {
      this.setState({errorMessage:"Please choose purchase from list"})
      return
    }

    if (this.isInvoice(transaction.transaction_type_id) && !transaction.invoices.length)
    {
      this.setState({errorMessage:"Please choose invoice from list"})
      return
    }

    if (this.isVatReturn(transaction.transaction_type_id) && !transaction.vatreturn.length)
    {
      this.setState({errorMessage:"Please choose VAT return period from list"})
      return
    }

    if (this.isTransfer(transaction.transaction_type_id))
    {
      if (this.state.linkedAccountId)
      {
        let direction = transaction.amount_in ? "from"  : "to"
        let accountName = this.getAccountName(this.state.linkedAccountId)
        transaction.notes = `Transfer ${direction} ${accountName}`
      }
      else
      {
        this.setState({errorMessage:"Please linked account from list"})
        return  
      }
    }

    if (!transaction.amount_out && !transaction.amount_in)
    {
      this.setState({errorMessage:"Please set transaction amount"})
      return
    }

    transaction.transaction_date = Moment(transaction.transaction_date).format('YYYY-MM-DD')

    if(this.isPurchase(transaction.transaction_type_id))
    {
      transaction.invoices = []
      transaction.vatreturn = []
      transaction.destinationAccount_id = null
    }

    if(this.isInvoice(transaction.transaction_type_id))
    {
      transaction.purchases = []
      transaction.vatreturn = []
      transaction.destinationAccount_id = null
    }

    if(this.isNotSpecialTransactionType(transaction.transaction_type_id))
    {
      transaction.invoices = []
      transaction.purchases = []
      transaction.vatreturn = []
      transaction.destinationAccount_id = null
    }

    if (this.isUserSpecific(transaction.transaction_type_id))
    {
      transaction.user_id = 2 // Only myself at the moment. Will need to add a selector if other people need to be accounted for
    }

    await callAccountsApi("transactions","saveTransaction",{transaction})
    this.props.close(true,"Transaction saved")
  }

  isUserSpecific = id => {
    for( let t of this.props.transactionTypes)
    {
      if(t.type_id === id)
      {
        return t.user_specific === 1
      }
    }

    return false
  }

  deleteTransaction = async () => {
    await callAccountsApi("transactions","deleteTransaction",{transactionId:this.state.transaction.transaction_id})
    this.props.close(true,"Transaction deleted")
  }

  handleTransactionDateChange = (field,date) => {
    let transaction = this.state.transaction
    transaction[field] = date
    this.setState({transaction},this.getVATRate)
  }
   
  handleChange = event => {
    let transaction = this.state.transaction

    transaction[event.target.name] = event.target.value
    
    if (event.target.name === "transaction_type_id")
    {
      if (this.isPurchase(event.target.value))
      {
        this.getPossiblePurchases()
      }

      if (this.isInvoice(event.target.value))
      {
        this.getPossibleInvoices()
      }

      if (this.isVatReturn(event.target.value))
      {
        this.getPossibleVATReturns()
      }

      if (this.isNotSpecialTransactionType(event.target.value))
      {
        transaction.notes = this.getSelectedTransactionType().type_name
      }
    }

    this.setState({transaction})
  }

  getSelectedTransactionType = () => {
    for (let t of this.props.transactionTypes)
    {
      if (t.type_id === this.state.transaction.transaction_type_id)
      {
        return t
      }
    }
  }

  handleChoosePurchase = event => {
    let transaction = this.state.transaction

    transaction.purchases = event.target.value.map(v => {return {id:v,text:this.getPurchase(v).detail}})
    transaction.invoices = []
    transaction.vatreturn = []

    this.setState({transaction},this.calculateTransactionValueFromPurchasesInvoicesandVATReturn)
  }

  handleChooseInvoice = event => {
    let transaction = this.state.transaction

    transaction.invoices = event.target.value.map(v => {return {id:v,text:this.getInvoice(v).invoicenumber}})
    transaction.purchases = []
    transaction.vatreturn = []

    this.setState({transaction},this.calculateTransactionValueFromPurchasesInvoicesandVATReturn)
  }

  handleChooseVATReturn = event => {
    let transaction = this.state.transaction

    transaction.vatreturn = [{id:event.target.value,text:this.getVATReturn(event.target.value).text}]
    transaction.invoices = []
    transaction.purchases = []

    this.setState({transaction},this.calculateTransactionValueFromPurchasesInvoicesandVATReturn)
  }

  handleChangeLinkedAccount =  event => {
    let linkedAccountId = event.target.value
    this.setState({linkedAccountId})
  }

  getVATReturn = id => {
    for (let p of this.state.vatreturns)
    {
      if (p.vat_id === id)
      {
        return p
      }
    }

    return {id, text : "unknown"}
  }


  getPurchase = id => {
    for (let p of this.state.purchases)
    {
      if (p.purchase_id === id)
      {
        return p
      }
    }

    return {id, detail : "unknown"}
  }

  getInvoice = id => {
    for (let p of this.state.invoices)
    {
      if (p.invoice_id === id)
      {
        return p
      }
    }

    return {id, invoicenumber : "unknown"}
  }

  isPurchase = id => {
    for (let t of this.props.transactionTypes)
    {
      if (t.type_id === id)
      {
        return t.purchase === 1
      }
    }
  }

  isInvoice = id => {
    return id === 2
  }

  isVatReturn = id => {
    return id === 9
  }
  
  isTransfer = id => {
    return id === 16
  }

  isNotSpecialTransactionType = id => {
    if (id === 2) { return false }
    if (id === 9) { return false }
    if (id === 16) { return false }

    for (let t of this.props.transactionTypes)
    {
      if (t.type_id === id)
      {
        return t.purchase !== 1
      }
    }

    return true
  }
  
  getAccountName = id => {
     for (let a=0;a<this.state.accounts.length;a++)
     {
       if (this.state.accounts[a].account_id === id)
       {
         return this.state.accounts[a].name
       }
     }
   }
   
   toggleInactiveAccounts = () => {
     let inactiveAccounts = !this.state.inactiveAccounts
     this.setState({inactiveAccounts})
   }

   formatTransactionTypeName = transaction => {
    return transaction.group_name ? transaction.group_name + (transaction.type_name !== transaction.group_name ? (' : ' + transaction.type_name) : '') : ''
   }

   formatTransactionDetail = transaction => {
    if (this.isInvoice(this.state.transaction.transaction_type_id)) {
      return (
        <React.Fragment>
          <span>Payment of invoices: </span>
          {transaction.invoices.map((i,x) => <Button onClick={event => this.props.actionClickHandler('invoice',i.id)} key={x}>{i.text}</Button>)}
        </React.Fragment>
      )
    }

    if (this.isPurchase(this.state.transaction.transaction_type_id)) {
      return (
        <React.Fragment>
          <span>Purchase: </span>
          {transaction.purchases.map((i,x) => <Button onClick={event => this.props.actionClickHandler('purchase',i.id)} key={x}>{i.text}</Button>)}
        </React.Fragment>
      )
    }

    if (this.isVatReturn(this.state.transaction.transaction_type_id)) {
      return (
        <React.Fragment>
          <span>VAT Return: </span>
          {transaction.vatreturn.map((i,x) => <Button onClick={event => this.props.actionClickHandler('vatreturn',i.id)} key={x}>{i.text}</Button>)}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <span>{this.state.transaction.notes}</span>
      </React.Fragment>
    )
  }

  getSelectedPurchaseLabels = ids => {
    return this.state.purchases.filter(p => ids.includes(p.purchase_id)).map(p => p.detail).join(", ")
  }

  getSelectedInvoiceLabels = ids => {
    return this.state.invoices.filter(p => ids.includes(p.invoice_id)).map(p => p.invoicenumber).join(", ")
  }

  toggleAllPurchaseTypes = () => {
    let allPurchaseTypes = !this.state.allPurchaseTypes
    this.setState({allPurchaseTypes},this.getPossiblePurchases)
  }

  toggleAllInvoices = () => {
    let allInvoices = !this.state.allInvoices
    this.setState({allInvoices},this.getPossibleInvoices)
  }
   
  handleAmountChange = (field,value) => {
    let transaction = this.state.transaction

    if (transaction[field] !== value)
    {
      transaction[field] = value

      let altField = field === "amount_out" ? "amount_in" : "amount_out"

      transaction[altField] = 0

      if (field !== "vat")
      {
        transaction.vat = 0
      }

      this.setState({transaction})
    }
  }

  getVATRate = async () => {
    let vatRate = await callAccountsApi("invoices","getVATRate",{date:this.state.transaction.transaction_date})
    this.setState({vatRate},this.calculateVAT)
  }

  calculateVAT = () => {
    if (this.state.mode === "edit")
    {
      let transaction = this.state.transaction

      let amount = transaction.amount_in ? transaction.amount_in : transaction.amount_out

      let vat = amount - (amount / (1 + this.state.vatRate))
      transaction.vat = vat

      this.setState({transaction})
    }
  }

  calculateTransactionValueFromPurchasesInvoicesandVATReturn = () => {
    let amount = 0
    let vat = 0

    if (this.isPurchase(this.state.transaction.transaction_type_id))
    {
      for (let p of this.state.transaction.purchases)
      {
        let purchase = this.getPurchase(p.id)
        amount -= purchase.amount
        vat += purchase.vat
      }
    }

    if(this.isInvoice(this.state.transaction.transaction_type_id))
    {
      for (let p of this.state.transaction.invoices)
      {
        let invoice = this.getInvoice(p.id)

        amount += invoice.grossAmount
        vat += invoice.vat
      }
    }

    if(this.isVatReturn(this.state.transaction.transaction_type_id))
    {
      for (let p of this.state.transaction.vatreturn)
      {
        let vatreturn = this.getVATReturn(p.id)

        amount -= vatreturn.vat_due
        vat += vatreturn.vat_due
      }
    }

    let transaction = this.state.transaction

    transaction.amount_out = amount < 0 ? amount * -1 : 0
    transaction.amount_in = amount > 0 ? amount : 0
    transaction.vat = vat

    this.setState({transaction})
  }

  getTransactionTypeOptions = () => {

    let options = []
    let lastGroupId = 0

    this.props.transactionTypes.forEach(p => {
      if (p.group_id !== lastGroupId)
      {
        lastGroupId = p.group_id
      options.push(<ListSubheader key={p.group_name} className="selectGroupHeader">{p.group_name}</ListSubheader>)
      }
      options.push(<MenuItem key={p.type_id} value={p.type_id} className="indentedSelectOption">{p.type_name}</MenuItem>)
    })

    return options
  }

  newPurchase = () => {
    
    if (!this.state.transaction.transaction_type_id)
    {
      StyleSheetList.setState({message:"Please choose transaction type first"})
      return  
    }

    let emptyPurchase = {
      purchase_type : this.state.transaction.transaction_type_id,
      amount : this.state.transaction.amount_out,
    }

    this.setState({openPurchaseForm:true,emptyPurchase})
  }

  closePurchaseForm = (refresh,purchase) => {
    this.setState({openPurchaseForm:false})

    if (purchase)
    {
      if (purchase.purchase_id)
      {
        console.log("We created a new purchase!!!!")
        let transaction = this.state.transaction
        transaction.purchases = [{id:purchase.purchase_id,text:purchase.detail}]
        transaction.amount_out = purchase.amount
        transaction.amount_in = 0
        transaction.vat = purchase.vat
        this.setState({transaction},this.getPossiblePurchases)
      }  
    }
  }

  viewStatement = async () => {
    let params = {
      account_id : this.state.transaction.account_id,
      transaction_date : this.state.transaction.transaction_date  
    }

    let selectedStatement = await callAccountsApi("statements","findStatementByDate",params) 

    this.setState({selectedStatement,openStatement:true})
  }

  closeSelectedStatement = () => {
    this.setState({selectedStatement:null,openStatement:false})
  }

  viewMode = (transaction,classes, open, close) => {

    let moneyDirection = transaction.amount_in ? "income" : "outgoing"

    return (
      <React.Fragment>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">
          <div>Transaction ID : {this.state.transaction.transaction_id}</div>
        </DialogTitle>
        <DialogContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className="tableHeader">Transaction Type</TableCell>
                <TableCell>{this.formatTransactionTypeName(transaction)}</TableCell>
                <TableCell className="tableHeader">Detail</TableCell>
                <TableCell>{this.formatTransactionDetail(transaction)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell className="tableHeader">Transaction Date</TableCell>
                <TableCell>{ValueFormatter.formatDate(transaction.transaction_date)}</TableCell>
                <TableCell className="tableHeader">{transaction.amount_in ? "Amount in" : "Amount out"}</TableCell>
                <TableCell className={classes[moneyDirection]}>{ValueFormatter.formatIncomeOrOutgoing(transaction.amount_in,transaction.amount_out)} {transaction.vat > 0 && <span>(VAT: {ValueFormatter.formatMoney(transaction.vat)})</span>}</TableCell>
              </TableRow>

              {transaction.long_note && 
                <TableRow>
                  <TableCell className="tableHeader">Notes</TableCell>
                  <TableCell colSpan={5}><pre>{transaction.long_note}</pre></TableCell>
                </TableRow>
              }

            </TableHead>
          </Table>
        </DialogContent>

        <DialogActions>
          <IconButton onClick={this.startEditMode} color="primary">
            <EditIcon/>
          </IconButton>

          <div style={{flex: '1 0 0'}} />

          <IconButton onClick={this.viewStatement} color="primary">
            <StatementIcon/>
          </IconButton>

          <IconButton onClick={close} color="primary">
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>

      <StatementDetail open={this.state.openStatement} statement={this.state.selectedStatement} close={this.closeSelectedStatement} mode="view"/>
      </React.Fragment>

    )
  } 

  editMode = (transaction,classes, open, close) => {
    return (
      <React.Fragment>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">
          {this.state.transaction.transaction_id && 
            <div>Transaction ID : {this.state.transaction.transaction_id}</div>
          }

          {!this.state.transaction.transaction_id && 
            <div>Create transaction</div>
          }
        </DialogTitle>

        <DialogContent>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{width:"25%"}}>
                  <FormControl className={classes.formControl} style={{minWidth: 200}}>
                    <InputLabel id="account_id-label">Account Name</InputLabel>
                    <Select value={this.state.transaction.account_id && this.state.accounts.length ? this.state.transaction.account_id : ""} onChange={this.handleChange} name="account_id" labelId="account_id-label">
                      {this.state.accounts.filter(a => a.active || this.state.inactiveAccounts).map(a => <MenuItem key={a.account_id} value={a.account_id}>{a.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </TableCell>

                <TableCell style={{width:"25%"}}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.inactiveAccounts}
                        onChange={this.toggleInactiveAccounts}
                        color="primary"
                      />
                    }
                    label="Show inactive accounts"
                  />
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell style={{width:"25%"}}>
                  <FormControl className={classes.formControl} style={{minWidth: 200}}>
                    <InputLabel id="transaction_type_id-label">Transaction Type</InputLabel>
                    <Select value={this.state.transaction.transaction_type_id ? this.state.transaction.transaction_type_id : ""} onChange={(event,item) => this.handleChange({target:{name:"transaction_type_id",value:item.props.value}})} name="transaction_type_id" labelId="transaction_type_id-label">
                    {this.getTransactionTypeOptions()}
                    </Select>
                  </FormControl>
                </TableCell>
                
                {this.isPurchase(this.state.transaction.transaction_type_id) &&
                <React.Fragment>
                  <TableCell style={{width:"25%"}}>
                    <FormControl className={classes.formControl} style={{minWidth: 200}}>
                      <InputLabel id="purchase_id-label">Purchases</InputLabel>

                      <Select value={this.state.transaction.purchases.map(p => p.id)} input={<Input />} onChange={this.handleChoosePurchase} name="purchase_id" labelId="purchase_id-label" multiple renderValue={(selected) => this.getSelectedPurchaseLabels(selected)}>
                        {this.state.purchases.map(p => 
                          <MenuItem key={p.purchase_id} value={p.purchase_id}>
                            <Checkbox checked={this.state.transaction.purchases.map(p => p.id).indexOf(p.purchase_id) > -1} />
                            <ListItemText primary={`${ValueFormatter.formatDate(p.purchase_date)} : ${p.detail}, ${ValueFormatter.formatMoney(p.amount)}`} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>

                  <TableCell style={{width:"25%"}}>
                    <Button variant="contained" onClick={this.newPurchase}>New purchase</Button>
                  </TableCell>

                  <TableCell style={{width:"25%"}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.allPurchaseTypes}
                          onChange={this.toggleAllPurchaseTypes}
                          color="primary"
                        />
                      }
                      label="Show all unassigned purchases"
                    />
                  </TableCell>
                </React.Fragment>
              }

              {this.isInvoice(this.state.transaction.transaction_type_id) &&
                <React.Fragment>
                  <TableCell style={{width:"25%"}}>
                    <FormControl className={classes.formControl} style={{minWidth: 200}}>
                      <InputLabel id="invoice_id-label">Invoices</InputLabel>
                      <Select value={this.state.transaction.invoices.map(p => p.id)} 
                        input={<Input />} 
                        onChange={this.handleChooseInvoice} n
                        ame="invoice_id" 
                        labelId="invoice_id-label" 
                        multiple 
                        renderValue={(selected) => this.getSelectedInvoiceLabels(selected)}>
                        {this.state.invoices.map(p => 
                          <MenuItem key={p.invoice_id} value={p.invoice_id}>
                            <Checkbox checked={this.state.transaction.invoices.map(p => p.id).indexOf(p.invoice_id) > -1} />
                            <ListItemText primary={`${p.invoicenumber} : ${p.client} : ${ValueFormatter.formatDate(p.invoice_date)}, ${ValueFormatter.formatMoney(p.amount)}`} />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>

                  <TableCell style={{width:"25%"}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.allInvoices}
                          onChange={this.toggleAllInvoices}
                          color="primary"
                        />
                      }
                      label="Show all invoices"
                    />
                  </TableCell>
                </React.Fragment>
              }

              {this.isVatReturn(this.state.transaction.transaction_type_id) &&
                <React.Fragment>
                  <TableCell style={{width:"25%"}}>
                    <FormControl className={classes.formControl} style={{minWidth: 200}}>
                      <InputLabel id="vat_id-label">VAT Return periods</InputLabel>
                      <Select value={this.state.transaction.vatreturn.length ? this.state.transaction.vatreturn.map(p => p.id)[0] : ""} 
                        input={<Input />} 
                        onChange={this.handleChooseVATReturn} 
                        name="vat_id" 
                        labelId="vat_id-label">
                        {this.state.vatreturns.map(p => 
                          <MenuItem key={p.vat_id} value={p.vat_id}>{ValueFormatter.formatVATPeriod(p.period)} : {ValueFormatter.formatMoney(p.vat_due)}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </React.Fragment>
              }

              {this.isTransfer(this.state.transaction.transaction_type_id) &&
                <React.Fragment>
                  <TableCell style={{width:"25%"}}>
                    <FormControl className={classes.formControl} style={{minWidth: 200}}>
                      <InputLabel id="destinationAccount_id-label">{this.state.transaction.amount_out ? "Destination" : "Source"} account</InputLabel>
                      <Select value={this.state.linkedAccountId ? this.state.linkedAccountId : ""} 
                        input={<Input />} 
                        onChange={this.handleChangeLinkedAccount} 
                        name="destinationAccount_id" 
                        labelId="destinationAccount_id-label">
                        {this.state.accounts.filter(a => a.account_id !== this.state.transaction.account_id && (a.active === 1 || this.state.inactiveAccounts)).map(p => 
                          <MenuItem key={p.account_id} value={p.account_id}>{p.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </TableCell>
                </React.Fragment>
              }

              {this.isNotSpecialTransactionType(this.state.transaction.transaction_type_id) &&
                <TableCell style={{width:"75%"}} colSpan={3}>
                  <TextField
                    id="notes"
                    name="notes"
                    label="Transaction Detail"
                    fullWidth
                    value={this.state.transaction.notes}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </TableCell>
              }

              </TableRow>

              <TableRow>
                <TableCell style={{width:"25%"}}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      orientation="portrait"
                      margin="normal"
                      label="Transaction Date"
                      format="dd MMM yyyy"
                      value={this.state.transaction.transaction_date}
                      onChange={date => this.handleTransactionDateChange("transaction_date",date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      autoOk={true}
                    />
                  </MuiPickersUtilsProvider>
                </TableCell>

                <TableCell style={{width:"25%"}}>

                  <CurrencyTextField
                    id="amount_out"
                    label="Gross Outgoing Amount"
                    value={this.state.transaction.amount_out}
                    currencySymbol="£"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event,value) => this.handleAmountChange("amount_out",value,event)}
                    onBlur={(event,value) => this.handleAmountChange("amount_out",value,event)}
                    inputProps={{style:{color:"red"}}}
                    variant="outlined"
                    minimumValue="0"
                  />
                </TableCell>

                <TableCell style={{width:"25%"}}>
                  <CurrencyTextField
                    id="amount_in"
                    label="Gross Incoming Amount"
                    value={this.state.transaction.amount_in}
                    currencySymbol="£"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event,value) => this.handleAmountChange("amount_in",value,event)}
                    onBlur={(event,value) => this.handleAmountChange("amount_in",value,event)}
                    inputProps={{style:{color:"green"}}}
                    variant="outlined"
                    minimumValue="0"
                  />
                </TableCell>

                <TableCell style={{width:"25%"}}>
                  <CurrencyTextField
                    id="vat"
                    label="VAT"
                    value={this.state.transaction.vat}
                    currencySymbol="£"
                    outputFormat="number"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(event,value) => this.handleAmountChange("vat",value,event)}
                    onBlur={(event,value) => this.handleAmountChange("vat",value,event)}
                    variant="outlined"
                    minimumValue="0"
                    style={{width:"120px"}}
                  />
                  <Button variant="contained" style={{marginTop:'10px',marginLeft:'10px'}} onClick={this.calculateVAT} color={this.state.transaction.vat === 0 ? "primary" : "default"}>Calc</Button>
                </TableCell>

              </TableRow>

              <TableRow>
                <TableCell colSpan={4}>
                  <TextField
                    id="long_note"
                    name="long_note"
                    label="Notes"
                    multiline
                    fullWidth
                    rows={4}
                    value={this.state.transaction.long_note}
                    variant="outlined"
                    onChange={this.handleChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>

        </DialogContent>

        <DialogActions>
          {(this.state.transaction.transaction_id > 0) && 
            <IconButton onClick={this.deleteTransaction} color="secondary">
              <DeleteIcon/>
            </IconButton>
          }

          <div style={{flex: '1 0 0'}} />

          <span className={classes.errorMessage}>{this.state.errorMessage}</span>
          
          <div style={{flex: '1 0 0'}} />

          <IconButton onClick={this.saveTransaction} color="primary">
            <SaveIcon/>
          </IconButton>

          <IconButton onClick={close} color="primary">
            <CloseIcon/>
          </IconButton>

        </DialogActions>
      </Dialog>

      <PurchaseDetail open={this.state.openPurchaseForm} purchase={this.state.emptyPurchase} close={this.closePurchaseForm} mode="edit" link={false}/>
    </React.Fragment>
    )
  }

  render() {
    const { classes, open, close } = this.props
    const transaction = this.state.transaction

    return this.state.mode === "view" ? this.viewMode(transaction,classes, open, close) : this.editMode(transaction,classes, open, close)
  }
}

export default withStyles(styles)(TransactionDetail)