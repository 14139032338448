import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Switch,Select,FormControl,Grid,MenuItem,Snackbar, Button} from '@material-ui/core'

import {DropzoneDialog} from 'material-ui-dropzone'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ActionCellRenderer from '../components/action-cell-renderer'
import LinksCellRenderer from './links-cell-renderer'

import getGridConfig from './grid-config'
import TransactionDetail from './transaction-detail'
import InvoiceDetail from '../invoices/invoice-detail'
import PurchaseDetail from '../purchases/purchase-detail'
import VATDetail from '../vat/vat-detail'

import callAccountsApi from '../data-provider'

import importBarclaycard from './import-barclaycard'
import importSantander from './import-santander'

const InvoiceUtils = require('../invoices/utils')
const Utils = require('../components/utils')

const importers = {
  importBarclaycard,
  importSantander
}

const styles = theme => ({
  paper: {
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    margin : 'auto',
    position : 'fixed',
    top : 160,
    bottom : 20,
    left : 20,
    right : 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    marginLeft : '15px',
  },
  errorMessage: {
    color:"red"
  },
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    transaction_date: "",
    group_name:"",
    notes:"TOTALS",
    amount_in : 0,
    amount_out : 0,
    vat : null,
    balance : 'total'
  }
  
  gridApi.forEachNodeAfterFilter(row => {
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
  })

  result.balance = (result.amount_in - result.amount_out).toFixed(2)
  result.amount_in = result.amount_in.toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)

  return [result]
}


class Transactions extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {openFileDialog:false,accounts:[],transactions:[],selectedAccountId:null,selectedTransaction:null,inactiveAccounts:false,selectedInvoice:null,selectedPurchase:null,message:"",transactionTypes:[],selectedAccount:{},selectedVATReturn:null}
  }

  async componentDidMount() {
    let {transaction_id} = this.props.match.params

    transaction_id = parseInt(transaction_id,10)
    
    if (transaction_id)
    {
      let selectedTransaction = await callAccountsApi("transactions","getTransaction",{transaction_id})
      let selectedAccountId = selectedTransaction.account_id

      while(!this.state.accounts.length) {await Utils.sleep(100)}

      let selectedAccount = this.getAccount(selectedAccountId)

      await this.getTransactions(selectedAccountId)
      while(!this.gridApi) {await Utils.sleep(100)}

      this.setState({selectedAccount,selectedAccountId},async () => {

        await Utils.sleep(1000)

        this.gridApi.forEachNode(node => {
          if (node.data.transaction_id === transaction_id)
          {
            node.setSelected(true,true)
            this.gridApi.ensureNodeVisible(node)
          }
        })
      })
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.searchTerm !== prevProps.searchTerm)
    {
      if (this.props.searchTerm.length)
      {
        this.searchTransactions(this.props.searchTerm)
      }
      else
      {
        this.clearSearch()
      }
    }
  }

  searchTransactions = async searchTerm => {
    this.setState({transactions:[]})
    let transactions = await callAccountsApi("transactions","searchTransactions",{account_id:this.state.selectedAccount.account_id,searchTerm})
    this.setState({transactions})

    if (!transactions.length)
    {
      let message= "No matching transactions found"
      this.setState({message})
    }
  }

  clearSearch = () => {
    this.setState({transactions:[]})
    this.getTransactions()
  }

  getAccounts = async () => {
    this.setState({accounts:[]})
    let accounts = await callAccountsApi("accounts","getAccounts")
    let selectedAccount = accounts.filter(a => a.active === 1 || this.state.inactiveAccounts)[0]
    this.setState({accounts,selectedAccount})
    let {transaction_id} = this.props.match.params

    if (!transaction_id)
    {
      this.getTransactions(selectedAccount.account_id)
    }
  }

  getTransactions = async (account_id = this.state.selectedAccount.account_id) => {
    this.setState({transactions:[],selectedTransaction:null})
    let transactions = await callAccountsApi("transactions","getTransactions",{account_id})
    this.setState({transactions})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  getTransactionTypes = async () => {
    let transactionTypes = await callAccountsApi("transactions","getTransactionTypes")
    this.setState({transactionTypes})
  }

  toggleInactiveAccounts = () => {
    let inactiveAccounts = !this.state.inactiveAccounts
    this.setState({inactiveAccounts})
  }

  handleAccountChange = event => {
    let selectedAccountId = event.target.value
    let selectedAccount = this.getAccount(selectedAccountId)
    this.setState({selectedAccount})
    this.getTransactions(selectedAccount.account_id)
  }

  getAccount = accountId => {
    return this.state.accounts.filter(a => a.account_id === accountId)[0]
  }
  
  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getAccounts()
    this.getTransactionTypes()
  }

  onFilterChanged = params => {
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  onRowDoubleClicked = row => {
    let selectedTransaction = row.data
    this.setState({selectedTransaction,detail_mode:"view"})
  }

  closeSelectedTransaction = (refresh,message) => {
    this.setState({selectedTransaction:null})

    if (refresh === true)
    {
      this.getTransactions()
    }

    if (message)
    {
      this.setState({message})
    }
  }

  view = data => {
    this.setState({selectedTransaction:data,detail_mode:"view"})
  }

  openNewTransactionForm = () => {
    let selectedTransaction = {
      account_id : this.state.selectedAccount.account_id,
      amount_out : 0,
      amount_in : 0,
      vat :0,
      invoices : [],
      purchases : [],
      vatreturn : [],
      notes : "",
      long_note : "",
    }

    this.setState({selectedTransaction,detail_mode:"edit"})
  }

  actionClickHandler = (action,data) => {
    switch(action)
    {
      case "view" : { return this.view(data) }
      case "invoice" : { return this.showInvoice(data) }
      case "purchase" : { return this.showPurchase(data) }
      case "vatreturn" : { return this.showVATReturn(data) }
      default: {console.log("actionClickHandler",action,data)}
    }
  }

  showInvoice = async invoice_id => {
    let selectedInvoice = (await callAccountsApi("invoices","getInvoices",{invoice_id}) )[0]
    this.setState({selectedInvoice})
  }

  showVATReturn = async vat_id => {
    let selectedVATReturn = (await callAccountsApi("vat","getReturn",{vat_id}) )[0]
    this.setState({selectedVATReturn})
  }
  
  closeShowVATReturn = () => {
    this.setState({selectedVATReturn:null})
  }

  closeShowInvoice = () => {
    this.setState({selectedInvoice:null})
  }

  invoicesActionClickHandler = async (action,data) => {
    switch(action)
    {
      case "edit" : { return this.editInvoice(data.invoice_id) }
      case "print" : { return InvoiceUtils.printInvoice(data.invoice_id) }
      case "download" : { return InvoiceUtils.downloadInvoice(data.invoice_id,data.invoicenumber) }
      case "email" : { return this.emailInvoice}
      default: {console.log("actionClickHandler",action,data)}
    }
  }

  editInvoice = invoice_id => {
    this.setState({message:"Edit Invoice"})
    this.props.history.push('/invoices/' + invoice_id)
  }

  emailInvoice = async invoice_id => {
    await callAccountsApi("invoices","sendInvoiceToClient",{invoice_id})
    this.setState({message:"Invoice emailed to client"})
  }

  showPurchase = async purchase_id => {
    let selectedPurchase = (await callAccountsApi("purchases","getPurchases",{purchase_id}) )[0]
    this.setState({selectedPurchase})
  }

  closeShowPurchase = () => {
    this.setState({selectedPurchase:null})
  }

  handleCloseMessage = () => {
    this.setState({message:""})
  }

  uploadStatement = () => {
    this.setState({openFileDialog:true})
  }

  handleSaveFileToUpload = async files => {
    this.setState({openFileDialog:false})
    let filedata = await Utils.fileReaderPromise(files[0])

    let base64data = filedata.replace(/data:[^;]*;base64,/,'')
    let statementData = atob(base64data)

    let sourceAccount = this.getAccount(this.state.selectedAccount.paymentSourceAccountId)

    await importers[this.state.selectedAccount.importer](this.state.selectedAccount,sourceAccount,statementData)

    this.setState({message:"Import complete"})

    this.getTransactions()
  }

  render() {
    const { classes } = this.props
    const GridConfig = getGridConfig(this.actionClickHandler,this.openNewTransactionForm)

    return (
      <React.Fragment>
        <FormControl className={classes.formControl} >
          {this.state.selectedAccount.account_id &&
            <Select value={this.state.selectedAccount.account_id} onChange={this.handleAccountChange} name="account_id" variant="outlined" style={{backgroundColor:'white'}}>
              {this.state.accounts.filter(a => a.active === 1 || this.state.inactiveAccounts).map(a => <MenuItem key={a.account_id} value={a.account_id}>{a.name}</MenuItem>)}
            </Select>
          }
        </FormControl>

        {this.state.selectedAccount.importer &&
          <Button variant="contained" color="default" style={{marginTop:'15px'}} onClick={this.uploadStatement}>Upload transactions</Button>
        }

        <span style={{float:"right",color:'#fff'}}>
          <Grid component="label" container item alignItems="center" spacing={1}>
            <Grid item>Active Accounts</Grid>
            <Grid item>
              <Switch
                checked={this.state.inactiveAccounts}
                onChange={this.toggleInactiveAccounts}
                color="primary"
              />
            </Grid>
            <Grid item>All Accounts</Grid>
          </Grid>
        </span>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.transactions}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              rowDeselection={true}
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                  {
                      statusPanel: 'agAggregationComponent',
                      statusPanelParams : {
                          // only show count and sum ('min', 'max', 'avg' won't be shown)
                          aggFuncs : ['min', 'max', 'avg', 'sum']
                      }
                  }
                ]
              }}
              frameworkComponents = {{
                actionCellRenderer : ActionCellRenderer,
                linksCellRenderer : LinksCellRenderer,
              }}
              components = {{
                customPinnedRowRenderer: CustomPinnedRowRenderer,

              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              onFilterChanged = {this.onFilterChanged}
              getRowStyle = {function(params) {
                if (params.data.transaction_type_id === 66) {
                    return { background: 'pink' };
                }
              }}
              >
            </AgGridReact>
          </div>

        </Paper>

        <TransactionDetail open={this.state.selectedTransaction !== null} transaction={this.state.selectedTransaction} close={this.closeSelectedTransaction} mode={this.state.detail_mode} transactionTypes={this.state.transactionTypes} actionClickHandler={this.actionClickHandler}/>
        <InvoiceDetail open={this.state.selectedInvoice !== null} invoice={this.state.selectedInvoice} close={this.closeShowInvoice} actionClickHandler={this.invoicesActionClickHandler} linkToEdit={true} link={false}/>
        <PurchaseDetail open={this.state.selectedPurchase !== null} purchase={this.state.selectedPurchase} close={this.closeShowPurchase} linkToEdit={true} mode="view" link={false}/>
        <VATDetail open={this.state.selectedVATReturn !== null} vatreturn={this.state.selectedVATReturn} close={this.closeShowVATReturn} linkToEdit={true} mode="view" link={false}/>
        
        <iframe id="pdfiframe" title="pdf"/>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />

        <DropzoneDialog
          open={this.state.openFileDialog}
          onSave={this.handleSaveFileToUpload.bind(this)}
          onDrop={this.handleSaveFileToUpload.bind(this)}
          acceptedFiles={[this.state.selectedAccount.importMimeType]}
          showPreviews={false}
          maxFileSize={5000000}
          filesLimit={1}
          dialogTitle={`Upload ${this.state.selectedAccount.name} statement`}
          onClose={event => {this.setState({openFileDialog:false})}}
        />

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Transactions))