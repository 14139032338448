import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Grid} from '@material-ui/core'

import Menu from './menu'
import TransactionGrid from './transactions-grid'
import TransactionsDetailView from './transactions-detail-view'

import callAccountsApi from '../data-provider'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    textAlign : 'center'
  },
  yearNumber : {
    fontSize : '32px',
    marginTop : '40px !important',
    marginBottom : '10px !important',
  }
})

class Reports extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {years:[],detail:{}}
  }
  
  componentDidMount() {
    this.getAnnualTransactions()
  }

  getAnnualTransactions = async () => {
    let years = await callAccountsApi("reports","getAnnualTransactions")
    console.log(years)
    this.setState({years})
  }

  actionClickHandler = async (action,row) => {
    let detail = {
      type : row.type_name,
      year : row.year,
      transactions : await callAccountsApi("reports","getTransactionsDetail",{year:row.year,type_id:row.type_id})
    } 

    console.log("detail",detail)

    this.setState({detail})
  }

  closeTransactions = () => {
    this.setState({detail:{}})
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Menu page="transactions"/>

        <Paper className={classes.paper}>
          <Grid container justify="space-around" direction="column">
            
            {Object.keys(this.state.years).map(y =>
              <React.Fragment>
                <Grid item><div className={classes.yearNumber}>{y}</div></Grid>
                
                <Grid item>
                  <TransactionGrid transactions={this.state.years[y]} year={parseInt(y,10)} actionClickHandler={this.actionClickHandler}/>
                </Grid>
              </React.Fragment>
            )}

            </Grid>
        </Paper>

        <TransactionsDetailView open={this.state.detail.transactions} year={this.state.detail.year} type={this.state.detail.type} transactions={this.state.detail.transactions} close={this.closeTransactions}/>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Reports))