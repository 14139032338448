import callAccountsApi from '../data-provider'
const Moment = require('moment')

if (!callAccountsApi)
{
  callAccountsApi = require('../data-provider').callAccountsApi
}

const importBarclaycard = async (account,sourceAccount,data) => {
  console.log("Importing Barclaycard",account)

  let rows = data.split("\n")

  console.log("cols",rows[0].split(','))

  rows.filter((r,i) => r.length && i > 0).reverse().map(r => processRow(account,sourceAccount,r.split(',')))
}

const processRow = async (account,sourceAccount,row) => {

  console.log("processRow...",row)

  let transaction = {
    account_id : account.account_id,
    transaction_date : Moment(row[2],"DD/MM/YYYY").format('YYYY-MM-DD'),
    transaction_type_id : 66, // New entry code
    notes : `${row[3]} :  ${row[15]}`
  }

  let amount = parseFloat(row[4],10)

  if (amount > 0)
  {
    transaction.amount_out = amount
  }
  else
  {
    transaction.amount_in = amount * -1 // Turn negative money to positive

    if (transaction.notes === "DIRECT DEBIT PAYMENT THANK YOU :  ")
    {
      // Transfer from main bank account
      transaction.notes = "Transfer from " + sourceAccount.name
      transaction.transaction_type_id =  16 // Account transfer
    }
  }

  await callAccountsApi("transactions","insertTransaction",{transaction})

  return "import complete"
}

export default importBarclaycard