import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogContent,DialogActions, TextField, Grid, IconButton, Switch} from '@material-ui/core'

import { MdClose as CloseIcon, MdSave as SaveIcon } from 'react-icons/md'
import callAccountsApi from '../data-provider'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
  income: {
    color:"green"
  },
  outgoing: {
    color:"red"
  },
})

class UserForm extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {user:null,errorMessage:null}
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user)
    {
      this.setState({user:this.props.user})
    }
  }

  handleChange = event => {
    let user = this.state.user
    user[event.target.name] = event.target.value
    this.setState({user})
  }

  save = async () => {
    await callAccountsApi("manage","saveUser",{user:this.state.user})
    this.props.close(true)
  }

  render() {
    const { classes, open ,close} = this.props
    const user = this.state.user

    return user ? (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogContent>
          <Grid container justify="space-around" style={{marginBottom:"30px"}}>
            <Grid item>
              <TextField
                id="username"
                name="username"
                label="Username"
                value={user.username ? user.username : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="name"
                name="name"
                label="Name"
                value={user.name ? user.name : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="newpassword"
                name="newpassword"
                label="New Password"
                value={user.newpassword ? user.newpassword : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

          </Grid>

          <Grid container justify="space-around" style={{marginBottom:"30px"}}>
            <Grid item>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                value={user.phone ? user.phone : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="email"
                name="email"
                label="Email"
                value={user.email ? user.email : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="address"
                name="address"
                label="Address"
                value={user.address ? user.address : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
                multiline
                rows={5}
              />
            </Grid>

          </Grid>

          <Grid container justify="space-around" style={{marginBottom:"30px"}}>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Inactive</Grid>
                <Grid item>
                  <Switch
                    checked={user.active === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"active",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Active</Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>External</Grid>
                <Grid item>
                  <Switch
                    checked={user.employee === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"employee",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Employee</Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Viewer</Grid>
                <Grid item>
                  <Switch
                    checked={user.edit_right === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"edit_right",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Editor</Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid container spacing={3} style={{marginBottom:"30px"}}>

          </Grid>

        </DialogContent>


        <DialogActions>
          <div style={{flex: '1 0 0'}} />
          <span className={classes.errorMessage}>{this.state.errorMessage}</span>
          <div style={{flex: '1 0 0'}} />
            
          <IconButton onClick={close} color="primary">
            <CloseIcon/>
          </IconButton>

          <IconButton onClick={this.save} color="primary">
            <SaveIcon/>
          </IconButton>
        </DialogActions>
      
      </Dialog>
    ) : ''
  }
}

export default withStyles(styles)(UserForm)