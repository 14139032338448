import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Button} from '@material-ui/core'

import UserIcon from '@material-ui/icons/Face'
import ClientIcon from '@material-ui/icons/LocationCity'
import AccountIcon from '@material-ui/icons/AccountBalance'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    overflowX: 'none',
    backgroundColor : '#fff',
    padding : 20,
    marginBottom : '20px'
  },
  icon : {
    fontSize : 72,
    verticalAlign : 'bottom',
    width : 60,
    marginBottom : 10,
    color : 'black'
  },
  iconText : {
    color : '#999',
    display : 'inline-block',
  },
  active : {
    color : '#44CBDD',
    fontWeight : 'bold'
  },
  gridItem : {
    textAlign : 'center'
  },
  helpText : {
    color : '#999',
    display : 'block',
    textAlign : 'center'
  },
})

class Menu extends Component {

  textClass = page => {

    const { classes } = this.props

    let classnames = `${classes.iconText}`

    if (page === this.props.page)
    {
      classnames += ` ${classes.active}`
    }

    return classnames
  }

  render() {

    const { classes } = this.props

    return (
      <Paper className={classes.paper}>

        <Grid container justify="space-around" alignItems="baseline">
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/manage/clients" className={this.textClass("clients")}><ClientIcon className={classes.icon}/><br/>Clients</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/manage/users" className={this.textClass("users")}><UserIcon className={classes.icon}/><br/>Users</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/manage/accounts" className={this.textClass("accounts")}><AccountIcon className={classes.icon}/><br/>Accounts</Button>
          </Grid>
        </Grid>

      </Paper>
    )
  }
}

export default withStyles(styles)(Menu)