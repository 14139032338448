import React from 'react'
import Moment from 'moment-timezone'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'

import Config from '../config'

const getGridConfig = (actionClickHandler,openNewStatementForm) => {

  let userData = Config.getConfig('userData')
  
  let gridconfig = {
   
    visibleRowBufferSize : 10,
  
    columnDefs : [
      {
        field: "name",
        headerName: "Account Name",
        menuTabs : [],
        filter: true,
        width : 300,
        sortable: true,
      },
      {
        field: "start_date",
        headerName: "Start date",
        menuTabs : [],
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['greaterThan','inRange'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        width : 200,
        sortable: true,
      },
      {
        field: "end_date",
        headerName: "End date",
        menuTabs : [],
        filter: 'agDateColumnFilter',
        filterParams : {
          filterOptions : ['lessThan','inRange'],
          suppressAndOrCondition : true,
          inRangeInclusive : true,
          comparator : function (filter, value) {
            let filterMoment = Moment(filter)
            let valueMoment = Moment(value).tz('Europe/London')
  
            if (valueMoment.isSame(filterMoment,'day')) return 0
            if (valueMoment.isBefore(filterMoment,'day')) return -1
            if (valueMoment.isAfter(filterMoment,'day')) return 1
          },
        },
        valueFormatter : item => ValueFormatter.formatDate(item.value),
        width : 200,
        sortable: true,
      },
      {
        field: "active",
        headerName: "Active",
        menuTabs : [],
        filter: true,
        valueFormatter : item => ValueFormatter.yesNo(item.value),
        width : 300,
        sortable: true,
      },
      {
        field: "scan_image",
        headerName: "[Create statement]",
        menuTabs : [],
        filter: false,
        sortable: false,
        cellRenderer: 'actionCellRenderer',
        cellRendererParams: item =>
          { return {
            clicked: actionClickHandler,
            view : true,
            print : true,
            download : true,
            printAndDownloadDisabled : item.data.scan_image.length === 0,
            scan_image : item.data.scan_image
        }},
        headerComponentFramework: (props) => {
          return userData.edit_right ? 
           (<Button onClick={openNewStatementForm} color="primary" startIcon={<AddIcon />}>new statement</Button>) 
           :
           (
           <div class="ag-cell-label-container">
            <div class="ag-header-cell-label">
                <span class="ag-header-cell-text">Action</span>
            </div>
          </div>)
        },
      },
    ]
  }

  return gridconfig
}

export default getGridConfig