import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Snackbar} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ActionCellRenderer from '../components/action-cell-renderer'

import callAccountsApi from '../data-provider'

import getGridConfig from './grid-config'
import EditView from './edit-view'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    journey_date : "",
    reason : "TOTALS",
    miles : 0,
    amount: 0,
  }
  
  gridApi.forEachNodeAfterFilter(row => {
    result.miles += row.data.miles ? parseFloat(row.data.miles) : 0
    result.amount += row.data.amount ? parseFloat(row.data.amount) : 0
  })

  result.miles = result.miles.toFixed(0)
  result.amount = result.amount.toFixed(2)

  return [result]
}


class Miles extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {miles:[],message:"",journey:{},users:[]}
  }

  async componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getMileage()
    this.getUsers()
  }
  
  onFilterChanged = () => {
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  getMileage = async () => {
    let miles = await callAccountsApi("mileage","getMiles")
    this.setState({miles})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  getUsers = async () => {
    let users = await callAccountsApi("users","getUsers")
    this.setState({users})
  }

  onRowDoubleClicked = row => {
    this.openEditView(row.data)
  }

  actionClickHandler = (action,journey) => {

    console.log("actionClickHandler",action,journey)
    switch(action)
    {
      case "new" : return this.openEditView({user_id:0,journey_date:(new Date())})
      case "edit" : return this.openEditView(journey)
      default: {}
    }
  }

  openEditView = journey => {
    this.setState({journey})
  }

  closeEditView = refresh => {
    this.setState({journey:{}})

    if (refresh)
    {
      this.getMileage()
    }
  }

  render() {
    const { classes } = this.props

    let GridConfig = getGridConfig(this.actionClickHandler)

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.miles}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams : {
                            // only show count and sum ('min', 'max', 'avg' won't be shown)
                            aggFuncs : ['min', 'max', 'avg', 'sum']
                        }
                    }
                ]
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              frameworkComponents = {{
                ActionCellRenderer : ActionCellRenderer,
              }}
              components = {{
                customPinnedRowRenderer: CustomPinnedRowRenderer,
              }}
              onFilterChanged = {this.onFilterChanged}
              >
            </AgGridReact>
          </div>

        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />
            
        <EditView journey={this.state.journey} open={this.state.journey.user_id !== undefined} close={this.closeEditView} users={this.state.users}/>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Miles))