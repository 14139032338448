import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Switch,Grid} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ActionCellRenderer from '../components/action-cell-renderer'

import getGridConfig from './grid-config'
import StatementDetail from './statement-detail'

import Moment from 'moment-timezone'

import callAccountsApi from '../data-provider'

const S3 = require('../components/aws-s3')

const styles = theme => ({
  paper: {
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    margin : 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
  },
  errorMessage: {
    color:"red"
  }
})

class Statements extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {selectedStatement:null,statements:[],inactiveAccounts:false,detail_mode:"view"}
  }

  getStatements = async () => {
    this.setState({statements:[]})
    let statements = await callAccountsApi("statements","getStatements")
    this.setState({statements})
  }

  toggleInactiveAccounts = () => {
    let inactiveAccounts = !this.state.inactiveAccounts
    this.setState({inactiveAccounts})
  }

  handleAccountChange = event => {
    console.log(event.target)
    let selectedAccount = event.target.value
    this.setState({selectedAccount})
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getStatements()
  }

  onRowDoubleClicked = row => {
    let selectedStatement = row.data
    this.setState({selectedStatement,detail_mode:"view"})
  }

  closeSelectedStatement = refresh => {
    this.setState({selectedStatement:null})

    if (refresh === true)
    {
      this.getStatements()
    }
  }

  view = data => {
    this.setState({selectedStatement:data,detail_mode:"view"})
  }

  getStatementImage = async statement => {
    let key = `bank_statements/${statement.account_id}/${Moment(statement.start_date).year()}/${statement.scan_image}`
     
    let scan = await S3.getFile('data.accounts.smartix.uk',key)

    if (scan)
    {
      let statementScanType = scan.ContentType
      let statementScanBuffer = Buffer.from(scan.Body)
      let blob = new Blob([statementScanBuffer], { type: statementScanType })
      return URL.createObjectURL(blob)
    }
  }

  print = async statement => {
    let blob_url = await this.getStatementImage(statement)

    var iframe = document.getElementById('pdfiframe')
    iframe.src=blob_url
    document.body.appendChild(iframe)
    iframe.onload = function() {
      iframe.contentWindow.print()
    }
  }

  download = async statement => {
    let blob_url = await this.getStatementImage(statement)
    let extension = statement.scan_image.split(".").pop()
    let filename = `${statement.name}-${Moment(statement.start_date).format("DD-MMM-YYYY")}.${extension}`

    const a = document.createElement('a')
    a.href = blob_url
    a.download = filename

    a.click()
  }

  actionClickHandler = (action,data) => {
    switch(action)
    {
      case "view" : return this.view(data)
      case "print" : return this.print(data)
      case "download" : return this.download(data)
      default: {}
    }
  }

  openNewStatementForm = () => {
    let selectedStatement = {
      account_id : 0,
      start_date : new Date(),
      end_date : new Date()
    }

    this.setState({selectedStatement,detail_mode:"edit"})
  }

  render() {
    const { classes } = this.props
    const GridConfig = getGridConfig(this.actionClickHandler,this.openNewStatementForm)

    return (
      <React.Fragment>
        <span style={{float:"right",color:'#fff'}}>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Active Accounts</Grid>
            <Grid item>
              <Switch
                checked={this.state.inactiveAccounts}
                onChange={this.toggleInactiveAccounts}
                color="primary"
              />
            </Grid>
            <Grid item>All Accounts</Grid>
          </Grid>
        </span>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.statements.filter(s => s.hide === 0).filter(s => s.active || this.state.inactiveAccounts)}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                  {
                    statusPanel: 'agAggregationComponent',
                    statusPanelParams : {
                      // only show count and sum ('min', 'max', 'avg' won't be shown)
                      aggFuncs : ['min', 'max', 'avg', 'sum']
                    }
                  }
                ]
              }}
              frameworkComponents = {{
                actionCellRenderer : ActionCellRenderer,
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              >
            </AgGridReact>
          </div>

        </Paper>

        <StatementDetail open={this.state.selectedStatement !== null} statement={this.state.selectedStatement} close={this.closeSelectedStatement} mode={this.state.detail_mode}/>
        <iframe id="pdfiframe" title="pdf"/>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Statements))