import Invoices from './invoices/index'
import Statements from './statements/index'
import Transactions from './transactions/index'
import Purchases from './purchases/index'
import Mileage from './mileage/index'
import Dividends from './dividends/index'
import Loans from './loans/index'
import VAT from './vat/index'
import ReportsTrialBalance from './reports/trial-balance'
import ReportsBankAccounts from './reports/bank-accounts'
import ReportsTransactions from './reports/transactions'
import ReportsLoans from './reports/loans'
import ReportsDividends from './reports/dividends'
import ReportsInvoices from './reports/invoices'
import ReportsExpenses from './reports/expenses'
import ReportsMileage from './reports/mileage'
import ManageClients from './manage_data/clients'
import ManageUsers from './manage_data/users'
import ManageAccounts from './manage_data/accounts'

import Logout from './logout'

const routes = [
  {
    name : "Invoices",
    component : Invoices,
    url : "/invoices",
    search : true,
    menu : true,
    exact : true,
  },
  {
    component : Invoices,
    url : "/invoices/:invoice_id",
    search : true,
    menu : false,
    exact : true,
  },
  {
    name : "Bank Statements",
    component : Statements,
    url : "/statements",
    search : false,
    menu : true,
    exact : true,
  },
  {
    name : "Account Transactions",
    component : Transactions,
    url : "/transactions",
    search : true,
    menu : true,
    exact : true,
  },
  {
    component : Transactions,
    url : "/transactions/:transaction_id",
    search : true,
    menu : false,
    exact : true,
  },
  {
    name : "Purchases",
    component : Purchases,
    url : "/purchases",
    search : true,
    menu : true,
    exact : true,
  },
  {
    name : "Business Mileage",
    component : Mileage,
    url : "/mileage",
    search : true,
    menu : true,
    exact : true,
  },
  {
    name : "Dividends",
    component : Dividends,
    url : "/dividends",
    search : true,
    menu : true,
    exact : true,
  },
  {
    name : "Loans",
    component : Loans,
    url : "/loans",
    search : true,
    menu : true,
    exact : true,
  },
  {
    name : "VAT Returns",
    component : VAT,
    url : "/vat",
    search : false,
    menu : true,
    exact : true,
  },
  {
    component : VAT,
    url : "/vat.:vat_id",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : VAT,
    url : "/vat/callback.:token",
    search : false,
    menu : false,
    exact : true,
  },
  {
    name : "Accountant's Reports",
    component : ReportsTrialBalance,
    url : "/reports/trial-balance",
    search : false,
    menu : true,
    exact : true,
  },
  {
    name : "Accountant's Reports",
    component : ReportsBankAccounts,
    url : "/reports/bank-accounts",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsTransactions,
    url : "/reports/transactions",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsLoans,
    url : "/reports/loans",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsDividends,
    url : "/reports/dividends",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsInvoices,
    url : "/reports/invoices",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsExpenses,
    url : "/reports/expenses",
    search : false,
    menu : false,
    exact : true,
  },
  {
    component : ReportsMileage,
    url : "/reports/mileage",
    search : false,
    menu : false,
    exact : true,
  },
  {
    name : "Manage Data",
    component : ManageClients,
    url : "/manage/clients",
    search : false,
    menu : true,
    exact : true,
    admin : true,
  },
  {
    component : ManageUsers,
    url : "/manage/users",
    search : false,
    menu : false,
    exact : true,
    admin : true,
  },
  {
    component : ManageAccounts,
    url : "/manage/accounts",
    search : false,
    menu : false,
    exact : true,
    admin : true,
  },
  {
    name : "Logout",
    component : Logout,
    url : "/logout",
    search : false,
    exact : true,        
    menu : true,
  },
]

export const getRoutes = userData => {
  return routes.filter(r => r.admin ? (userData ? (userData.edit_right === 1) : false) : true)
}