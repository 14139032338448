import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {Paper, Grid, Button} from '@material-ui/core'

import BankAccountsIcon from '@material-ui/icons/AccountBalance'
import MileageIcon from '@material-ui/icons/DriveEta'
import TransactionsIcon from '@material-ui/icons/List'
import { GiScales as TrialBalanceIcon} from 'react-icons/gi'
import { FaPiggyBank as DividendsIcon } from 'react-icons/fa'
import { MdHotel as ExpensesIcon } from 'react-icons/md'
import InvoicesIcon from './invoice.png'
import LoansIcon from './loan.png'
import ViewIcon from '@material-ui/icons/Visibility'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    overflowX: 'none',
    backgroundColor : '#fff',
    padding : 20,
    marginBottom : '20px'
  },
  icon : {
    fontSize : 72,
    verticalAlign : 'bottom',
    width : 60,
    marginBottom : 10,
    color : 'black'
  },
  iconText : {
    color : '#999',
    display : 'inline-block',
  },
  active : {
    color : '#44CBDD',
    fontWeight : 'bold'
  },
  gridItem : {
    textAlign : 'center'
  },
  helpText : {
    color : '#999',
    display : 'block',
    textAlign : 'center'
  },
})

class Menu extends Component {

  textClass = page => {

    const { classes } = this.props

    let classnames = `${classes.iconText}`

    if (page === this.props.page)
    {
      classnames += ` ${classes.active}`
    }

    return classnames
  }

  render() {

    const { classes } = this.props

    return (
      <Paper className={classes.paper}>

        <Grid container justify="space-around" alignItems="baseline">
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/trial-balance" className={this.textClass("trial-balance")}><TrialBalanceIcon className={classes.icon}/><br/>Trial balance</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/bank-accounts" className={this.textClass("bank-accounts")}><BankAccountsIcon className={classes.icon}/><br/>Bank accounts</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/transactions" className={this.textClass("transactions")}><TransactionsIcon className={classes.icon}/><br/>Transactions</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/invoices" className={this.textClass("invoices")}><img src={InvoicesIcon} className={classes.icon}  alt=""/><br/>Invoices</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/dividends" className={this.textClass("dividends")}><DividendsIcon className={classes.icon}/><br/>Dividends</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/loans" className={this.textClass("loans")}><img src={LoansIcon} className={classes.icon} alt=""/><br/>Loans</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/expenses" className={this.textClass("expenses")}><ExpensesIcon className={classes.icon}/><br/>Expenses</Button>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button component={ Link } to="/reports/mileage" className={this.textClass("mileage")}><MileageIcon className={classes.icon}/><br/>Mileage</Button>
          </Grid>
        </Grid>

        <div className={classes.helpText}>Right click on any grid to export to Excel. Double click a row (or click <ViewIcon style={{marginBottom:'-5px'}}/>) to drill down.</div>

      </Paper>
    )
  }
}

export default withStyles(styles)(Menu)