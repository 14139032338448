import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import {Paper,Grid,Switch} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import callAccountsApi from '../data-provider'

import ActionCellRenderer from '../components/action-cell-renderer'
import getGridConfig from './grid-config-users'
import Menu from './menu'
import UserForm from './user-form'


const styles = theme => ({
  paper: {
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    margin : 'auto',
    position : 'fixed',
    top : 300,
    bottom : 20,
    left : 20,
    right : 20,
  },
})

class ManageUsers extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {users:[],user:null,showInactive:false}
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers = async () => {
    let users = await callAccountsApi("users","getUsers")
    this.setState({users})
  }

  actionClickHandler = (action,user) => {
    switch(action)
    {
      case "new" : return this.openForm({})
      case "edit" : return this.openForm(user)
      default: {}
    }
  }
  
  onRowDoubleClicked = row => {
    this.openForm(row.data)
  }

  openForm = user => {
    this.setState({user})
  }

  closeForm = () => {
    this.setState({user:null})
  }

  toggleInactive = () => {
    let showInactive = !this.state.showInactive
    this.setState({showInactive})
  }

  render() {
    const { classes } = this.props

    let columnDefs = getGridConfig(this.actionClickHandler)
    let users = this.state.users.filter(c => c.active || this.state.showInactive)

    return (
      <React.Fragment>
          
        <Menu page="users"/>

        <span style={{float:"right",color:'#fff'}}>
          <Grid component="label" container item alignItems="center" spacing={1}>
            <Grid item>Active Users</Grid>
            <Grid item>
              <Switch
                checked={this.state.showInactive}
                onChange={this.toggleInactive}
                color="primary"
              />
            </Grid>
            <Grid item>All Users</Grid>
          </Grid>
        </span>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {width: '100%' }}>
            <AgGridReact rowData={users} columnDefs={columnDefs} domLayout='autoHeight' frameworkComponents={{actionCellRenderer : ActionCellRenderer}} onRowDoubleClicked={this.onRowDoubleClicked}/>
          </div>
        </Paper>

        <UserForm open={this.state.user !== null} close={this.closeForm} user={this.state.user} />

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(ManageUsers))