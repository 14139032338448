const apimode = "remote"
const hmrcmode = "test"

var hmrc = {
  test : {
    redirect_uri : apimode === "remote" ? "https://accounts.smartix.uk/vat" : "http://localhost:3020/live/vat",
    hmrc_url : "https://test-api.service.hmrc.gov.uk/oauth/authorize",
    hmrc_client_id : "wi1CMy2BZN0UP4q9rf0kruFz3lKM",
  },
  live : {
    redirect_uri : "https://accounts.smartix.uk/vat",
    hmrc_url : "https://api.service.hmrc.gov.uk/oauth/authorize",
    hmrc_client_id : "c3Ca6sHpzw9g5KoPYDMQAyKPypCr",
  }
}

var config = {

  apptitle : "Smartix Accounts",
  adminApi : apimode === "local" ? "http://localhost:3000/live/" : "https://api.smartix.uk/accounts/",

  hmrc_mode : hmrcmode,
  hmrc_url : hmrc[hmrcmode].hmrc_url,
  redirect_uri : hmrc[hmrcmode].redirect_uri,
  hmrc_client_id : hmrc[hmrcmode].hmrc_client_id,

  userData : {},
}

module.exports = {

  setConfig : function(key,value)
  {
    config[key] = value
  },

  getConfig : function(key)
  {
    return config[key]
  },

  deleteConfig : function(key)
  {
    delete config[key]
  },

  isLocal : function()
  {
    let url = this.getConfig("adminApi")
    return (url.substr(7,9) === "localhost")
  },

  getAPIKey : function()
  {
    let url = this.getConfig("adminApi")

    if (url.substr(7,9) === "localhost")
    {
      return config.keys.local
    }
    else
    {
      return config.keys.live
    }
  },
}