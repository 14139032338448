import callAccountsApi from '../data-provider'

export const printInvoice = async invoice_id => {
  let pdfData = await callAccountsApi("invoices","getInvoicePDF",{invoice_id})
  let invoicePDF = Buffer.from(pdfData.data)
  var blob = new Blob([invoicePDF], { type: 'application/pdf' })
  var blob_url = URL.createObjectURL(blob)
  var iframe = document.getElementById('pdfiframe')
  iframe.src=blob_url
  document.body.appendChild(iframe)
  iframe.onload = function() {
    iframe.contentWindow.print()
  }
}

export const downloadInvoice = async (invoice_id,invoicenumber) => {
  let pdfData = await callAccountsApi("invoices","getInvoicePDF",{invoice_id})
  let invoicePDF = Buffer.from(pdfData.data)
  console.log(invoicePDF)

  var blob = new Blob([invoicePDF], { type: 'application/pdf' })
  var url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = invoicenumber + '.pdf'

  a.click()
}