import callAccountsApi from '../data-provider'
const Moment = require('moment')

if (!callAccountsApi)
{
  callAccountsApi = require('../data-provider').callAccountsApi
}

const importSantander = async (account,sourceAccount,data) => {
  let rows = data.replace(/[^0-9A-Za-z.,: \-\n]/g,'').split("\n")

  console.log(rows)

  if (checkAccount(account,rows))
  {
    let rowsToProcess = groupRows(rows)
    
    console.log("rowsToProcess",rowsToProcess)

    rowsToProcess.map(r => processRow(account,sourceAccount,r))

    return "import complete"
  }
  else
  {
    return "Wrong statement for this account. Aborting"
  }
}

const checkAccount = (account,rows) => {

  let requiredAccountNumLast4Digits = account.account_number.substr(4,4).trim()

  for (let r of rows) {
    if (r.substr(0,7) === "Account")
    {
      let statementAccountNumLast4Digits = r.split(" ").pop().toString().trim()
      return statementAccountNumLast4Digits === requiredAccountNumLast4Digits
    }
  }
}

const groupRows = rows => {

  let groupedRows = []
  let dataRowBeingBuilt = {}

  for (let row of rows)
  {
    let rowData = row.split(":")

    console.log("rowData[0]",rowData[0])

    switch(rowData[0])
    {
      case "Date": {
        let transaction_date = Moment(rowData[1],"DD/MM/YYYY").format('YYYY-MM-DD')
        dataRowBeingBuilt = {transaction_date}
        break
      }

      case "Description": {
        let notes = rowData[1].trim()
        dataRowBeingBuilt.notes = notes
        break
      }

      case "Amount": {
        let amount = parseFloat(rowData[1])

        console.log("amount",amount,rowData[1])

        if (amount > 0)
        {
          dataRowBeingBuilt.amount_in = amount
        }
        else
        {
          dataRowBeingBuilt.amount_out = amount * -1
        }

        groupedRows.push(dataRowBeingBuilt)
        
        break
      }

      default: {} // ignore
    }
  }

  return groupedRows.reverse()
}
const processRow = async (account,sourceAccount,transaction) => {

  transaction.account_id = account.account_id

  if (transaction.amount_out > 0)
  {
    switch (transaction.notes)
    {
      case "DIRECT DEBIT PAYMENT TO BCARD COMMERCIAL REF 5312140765306887, MANDATE NO 0041": {

        transaction.notes = "Transfer to Barclaycard (0041)"
        transaction.transaction_type_id = 16 // Account transfer
        break
      }

      case "TRANSFER TO HAZARDOUS FROG LTD": {

        transaction.notes = "Transfer to " + (account.account_id === 10 ? "Santander Savings" :"Santander Current") 
        transaction.transaction_type_id = 16 // Account transfer
        break
      }

      case "REGULAR TRANSFER PAYMENT TO ACCOUNT 090135 58034489, MANDATE NO 25": {
        transaction.notes = "Home office rental (0025)" 
        transaction.transaction_type_id = 11
        break
      }

      case "REGULAR TRANSFER PAYMENT TO ACCOUNT 090135 58034489, MANDATE NO 45": {
        transaction.notes = "Salary: Ian Bale (0045)" 
        transaction.transaction_type_id = 6
        break
      }

      case "DIRECT DEBIT PAYMENT TO SJP REF C050531451247033, MANDATE NO 0045": {
        transaction.notes = "Pension: Ian Bale (0045)" 
        transaction.transaction_type_id = 5
        break
      }

      case "DIRECT DEBIT PAYMENT TO ROYAL LONDON REF 14144900078320255M, MANDATE NO 0003": {
        transaction.transaction_type_id = 13
        break
      }

      case "DIRECT DEBIT PAYMENT TO ROYAL LONDON REF 14144900054331639M, MANDATE NO 0003": {
        transaction.notes = "Royal London income protection insurance (0003)" 
        transaction.transaction_type_id = 3
        break
      }

      case "STANDING ORDER VIA FASTER PAYMENT TO JKMS REFERENCE HAZARDOUS FROG , MANDATE NO 0161": {
        transaction.notes = "Business development (0125)" 
        transaction.transaction_type_id = 65
        break
      }

      case "STANDING ORDER VIA FASTER PAYMENT TO P CATTON REFERENCE HF DONATION , MANDATE NO 0151" : {
        transaction.notes = "Charity donation : Ferngate (0151)" 
        transaction.transaction_type_id = 67
        break
      }

      default : {
        transaction.transaction_type_id = 66 // New entry code
      }
    }
  }

  if (transaction.amount_in > 0)
  {
    switch (transaction.notes)
    {
      case "TRANSFER FROM HAZARDOUS FROG LTD": {

        transaction.notes = "Transfer from " + sourceAccount.name
        transaction.transaction_type_id = 16 // Account transfer
        break
      }

      case "INTEREST PAID AFTER TAX 0.00 DEDUCTED": {
        transaction.transaction_type_id = 15 // Bank Interest
        break
      }

      default : {
        transaction.transaction_type_id = 66 // New entry code
      }
    }
  }

  await callAccountsApi("transactions","insertTransaction",{transaction})
}

export default importSantander