import React, { Component } from 'react'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ValueFormatter from '../components/value-formatter'

import ActionCellRenderer from '../components/action-cell-renderer'

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    monthNum:"",
    month:"TOTALS",
    amount_in : 0,
    amount_out : 0,
  }
  
  gridApi.forEachNode(row => {
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
  })

  result.amount_in = result.amount_in.toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)

  return [result]
}

const numberSort = (num1,num2) => {
  return num1 - num2
}

class BankAccountGrid extends Component {

  onGridReady = params => {
    params.api.setPinnedBottomRowData(createTotalPinnedData(params.api))
  }

  render() {

    return (
      <div className="ag-theme-alpine" style={ {height: '600px', width: '450px'} }>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.transactions}
          onRowDoubleClicked={item => this.props.actionClickHandler("view",item.data)}
          columnDefs={[
            {
              field: "month",
              headerName: "Month",
              cellClass : 'textGridCell',
              menuTabs : [],
              sortable: false,
              comparator: numberSort,
              filter: false,
              width : 120,
            },
            {
              field: "amount_out",
              headerName: "Outgoings",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 120,
            },
            {
              field: "amount_in",
              headerName: "Income",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 120,
            },
            {
              field: "monthNum",
              headerName: "",
              cellRenderer: 'actionCellRenderer',
              cellRendererParams: item => {
                return {
                  clicked: this.props.actionClickHandler,
                  view : true,
                }
              },
              menuTabs : [],
              filter: false,
              width : 75,
            },
          ]}
          frameworkComponents = {{
            actionCellRenderer : ActionCellRenderer,
          }}
          components = {{
            customPinnedRowRenderer: CustomPinnedRowRenderer,
          }}
          >
        </AgGridReact>
      </div>
    )
  }
}

export default (BankAccountGrid)