import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogTitle,DialogContent,DialogActions, IconButton, Grid, InputLabel, FormControl, Switch, Select, MenuItem, TextField} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'

import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import callAccountsApi from '../data-provider'

const styles = theme => ({
  message: {
    color:"red"
  },
})

class EditView extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {journey:{},message:null,showInactiveUsers:false,cars:[]}
  }

  async componentDidUpdate(prevProps) {
    if (this.props.journey !== prevProps.journey)
    {
      let journey = {...this.props.journey}

      let filteredUsers = this.props.users.filter(u => u.employee && (u.active || this.state.showInactiveUsers))

      if (!journey.user_id)
      {
        journey.user_id = filteredUsers.length === 1 ? filteredUsers[0].user_id : ""
      }

      this.setState({journey,message:null,showInactiveUsers:false},() =>{
        this.getCars()
      })
    }
  }

  getCars = async () => {
    if (this.state.journey.user_id)
    {
      let cars = await callAccountsApi("mileage","getCars",{user_id:this.state.journey.user_id})

      this.setState({cars})

      let filteredCars = cars.filter(c => c.active || this.state.showInactiveUsers || c.car_id === this.state.journey.car_id)

      if (filteredCars.length === 1)
      {
        let journey = this.state.journey
        journey.car_id = filteredCars[0].car_id
        this.setState({journey})
      }
    }
  }

  handleChange = event => {

    console.log("handleChange",event)

    let journey = this.state.journey

    journey[event.target.name] = event.target.value

    let name = event.target.name

    this.setState({journey},() => {
      if (name === "user_id")
      {
        this.getCars()
      }
    })
  }

  handleDateChange = date => {
    let journey = this.state.journey
    journey.journey_date = date
    this.setState({journey})
  }

  toggleInactiveUsers = () => {
    let showInactiveUsers = !this.state.showInactiveUsers
    this.setState({showInactiveUsers})
  }

  save = async () => {
    let doSave = false
    let journey = this.state.journey

    for (let p in journey)
    {
      console.log(p,journey[p],this.props.journey[p])
      if (journey[p] !== this.props.journey[p])
      {
        doSave = true
        break
      }
    }

    if (doSave)
    {
      if (!journey.user_id)
      {
        this.setState({message:"Choose person"})
        doSave = false
      }

      if (!journey.car_id)
      {
        this.setState({message:"Choose car"})
        doSave = false
      }

      if (! journey.journey || !journey.journey.length)
      {
        this.setState({message:"Enter journey"})
        doSave = false
      }

      if (!journey.miles)
      {
        this.setState({message:"Enter mileage"})
        doSave = false
      }
    }

    if (doSave)
    {
      await callAccountsApi("mileage","saveJourney",{journey})
    }

    this.props.close(doSave)
  }

  deleteJourney = async () => {
    let journey = this.state.journey

    await callAccountsApi("mileage","deleteJourney",{journey})
    this.props.close(true)
  }

  render() {
    const { classes, open, close } = this.props

    let journey = this.state.journey 
    let users = this.props.users.filter(u => u.employee && (u.user_id === journey.user_id || u.active || this.state.showInactiveUsers))
    let cars = this.state.cars.filter(c => c.active || this.state.showInactiveUsers || c.car_id === journey.car_id)

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">Business Mileage
          <span style={{float:"right",fontWeight:"normal",fontSize:"12px"}}>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Active Users/Cars</Grid>
                <Grid item>
                  <Switch
                    checked={this.state.showInactiveUsers}
                    onChange={this.toggleInactiveUsers}
                    color="primary"
                  />
                </Grid>
                <Grid item>All Users/Cars</Grid>
              </Grid>
            </span>
        </DialogTitle>
        <DialogContent>

        </DialogContent>
          <Grid container justify="space-around">

            <FormControl className={classes.formControl} style={{minWidth: 200}}>
              <InputLabel id="user_id-label">Person</InputLabel>
              <Select value={journey.user_id ? journey.user_id : ""} onChange={this.handleChange} name="user_id" labelId="user_id-label">
                {users.map(u => <MenuItem key={u.user_id} value={u.user_id}>{u.name}</MenuItem>)}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} style={{minWidth: 200}}>
              <InputLabel id="car_id-label">Car</InputLabel>
              <Select value={journey.car_id ? journey.car_id : ""} onChange={this.handleChange} name="car_id" labelId="car_id-label">
                {cars.map(c => <MenuItem key={c.car_id} value={c.car_id}>{c.car_make} {c.registration}</MenuItem>)}
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                orientation="portrait"
                margin="normal"
                label="Journey date"
                format="dd MMM yyyy"
                value={journey.journey_date}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>

            <CurrencyTextField
              id="miles"
              label="Miles"
              value={this.state.journey.miles}
              currencySymbol=""
              outputFormat="number"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event,value) => this.handleChange({target:{name:"miles",value}})}
              onBlur={(event,value) => this.handleChange({target:{name:"miles",value}})}
              variant="outlined"
              minimumValue="0"
              decimalPlaces={0}
            />
          </Grid>

          <TextField
            id="journey"
            name="journey"
            label="Journey"
            fullWidth
            value={this.state.journey.journey ? this.state.journey.journey : ""}
            variant="outlined"
            onChange={(event,value) => this.handleChange({target:{name:"journey",value:event.target.value}})}
            style={{margin:"15px",width:"90%"}}
          />

          <TextField
            id="reason"
            name="reason"
            label="reason"
            fullWidth
            value={this.state.journey.reason ? this.state.journey.reason : ""}
            variant="outlined"
            onChange={(event,value) => this.handleChange({target:{name:"reason",value:event.target.value}})}
            style={{margin:"15px",width:"90%"}}
          />

        <DialogActions>

        {(this.state.journey.mileage_id > 0) && 
          <IconButton onClick={this.deleteJourney} color="secondary">
            <DeleteIcon/>
          </IconButton>
        }

        <div style={{flex: '1 0 0'}} />
        <span className={classes.message}>{this.state.message}</span>
        <div style={{flex: '1 0 0'}} />

        <IconButton onClick={this.props.close} color="primary">
          <CloseIcon/>
        </IconButton>

        {this.state.journey &&
          <IconButton onClick={this.save} color="primary">
            <SaveIcon/>
          </IconButton>
        }
      </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditView)