import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogTitle,DialogContent,DialogActions, IconButton } from '@material-ui/core'
import { MdClose as CloseIcon } from 'react-icons/md'

import ValueFormatter from '../components/value-formatter'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
  income: {
    color:"green"
  },
  outgoing: {
    color:"red"
  },
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    notes:"TOTALS",
    amount_in : 0,
    amount_out : 0,
    vat : 0,
  }
  
  gridApi.forEachNode(row => {
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
  })

  result.amount_in = result.amount_in.toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)

  return [result]
}

const numberSort = (num1,num2) => {
  return num1 - num2
}

class TransactionDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {errorMessage:"",originalStatement:{},transaction:{},edit:false,mode:'view',accounts:[],inactiveAccounts:false,purchases:[],invoices:[],allPurchaseTypes:false,allInvoices:false,vatRate:0,openPurchaseForm:false,emptyPurchase:{},openStatement:false,vatreturns:[],selectedVATReturn:null}
  }

  onGridReady = params => {
    params.api.setPinnedBottomRowData(createTotalPinnedData(params.api))
  }

  render() {
    const { open, close } = this.props

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">
          <div>{this.props.month} {this.props.year} : {this.props.account}</div>
        </DialogTitle>
        <DialogContent>
          <div className="ag-theme-alpine" style={ {height: '500px', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.props.transactions}
              columnDefs={[
                {
                  field: "group_name",
                  headerName: "Group",
                  cellClass : 'textGridCell',
                  menuTabs : [],
                  sortable: false,
                  comparator: numberSort,
                  filter: false,
                  width : 200,
                },
                {
                  field: "type_name",
                  headerName: "Type",
                  cellClass : 'textGridCell',
                  menuTabs : [],
                  sortable: false,
                  comparator: numberSort,
                  filter: false,
                  width : 200,
                },
                {
                  field: "notes",
                  headerName: "Notes",
                  cellClass : 'textGridCell',
                  menuTabs : [],
                  sortable: false,
                  comparator: numberSort,
                  filter: false,
                  width : 400,
                },
                {
                  field: "amount_out",
                  headerName: "Outgoings",
                  headerClass : "rightAlignHeader",
                  cellClass : 'numberGridCell',
                  menuTabs : [],
                  sortable: false,
                  valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
                  filter: true,
                  width : 120,
                },
                {
                  field: "amount_in",
                  headerName: "Income",
                  headerClass : "rightAlignHeader",
                  cellClass : 'numberGridCell',
                  menuTabs : [],
                  sortable: false,
                  valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
                  filter: true,
                  width : 120,
                },
                {
                  field: "vat",
                  headerName: "VAT",
                  headerClass : "rightAlignHeader",
                  cellClass : 'numberGridCell',
                  menuTabs : [],
                  sortable: false,
                  valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
                  filter: true,
                  width : 120,
                },
              ]}
              components = {{
                customPinnedRowRenderer: CustomPinnedRowRenderer,
              }}
              >
            </AgGridReact>
          </div>
        </DialogContent>

        <DialogActions>
          <IconButton onClick={close} color="primary">
            <CloseIcon/>
          </IconButton>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(TransactionDetail)