import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Grid} from '@material-ui/core'

import Menu from './menu'
import TransactionGrid from './bank-accounts-grid'
import BankAccountDetailView from './bank-account-detail-view'

import callAccountsApi from '../data-provider'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    overflowX: 'auto',
    backgroundColor : '#fff',
    padding : 10,
    textAlign : 'center'
  },
  yearNumber : {
    fontSize : '32px',
  },
  accountName : {
    fontSize : '24px',
    marginTop : '40px !important',
    marginBottom : '10px !important',
  }
})

class Reports extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {years:[],monthDetail:{}}
  }
  
  componentDidMount() {
    this.getAccountSummary()
  }

  getAccountSummary = async () => {
    let years = await callAccountsApi("reports","getAccountSummary")
    console.log(years)
    this.setState({years})
  }

  actionClickHandler = async (action,row) => {
    let monthDetail = {
      account : row.accountName,
      year : row.yearNum,
      month : row.month,
      transactions : await callAccountsApi("reports","getMonthAccounts",{year:row.yearNum,month:row.monthNum,account_id:row.account_id})
    } 

    console.log("monthDetail",monthDetail)

    this.setState({monthDetail})
  }

  closeTransactions = () => {
    this.setState({monthDetail:{}})
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Menu page="bank-accounts"/>

        <Paper className={classes.paper}>
          <Grid container justify="space-around" direction="row">
            
            {Object.keys(this.state.years).map(y =>
              <Grid item key={y}>
                <Grid container direction="column" justify="space-around">
                  <Grid item><div className={classes.yearNumber}>{y}</div></Grid>
                  
                  {Object.keys(this.state.years[y]).map(a =>
                    <React.Fragment key={a}>
                      <Grid item>
                        <div className={classes.accountName}>{a}</div>
                      </Grid>
                      <Grid item>
                        <TransactionGrid transactions={this.state.years[y][a]} year={parseInt(y,10)} actionClickHandler={this.actionClickHandler}/>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            )}

            </Grid>
        </Paper>

        <BankAccountDetailView open={this.state.monthDetail.transactions} year={this.state.monthDetail.year} month={this.state.monthDetail.month} account={this.state.monthDetail.account} transactions={this.state.monthDetail.transactions} close={this.closeTransactions}/>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Reports))