import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Switch,Grid,Snackbar} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import getGridConfig from './grid-config'
import callAccountsApi from '../data-provider'

import ActionCellRenderer from '../components/action-cell-renderer'

import InvoiceDetail from './invoice-detail'
import EditInvoiceForm from './edit-invoice-form'

import Config from '../config'

import {printInvoice, downloadInvoice} from './utils'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    invoicenumber : "",
    invoice_date: "",
    transaction_date:"",
    client:"",
    grossAmount : 0,
    vat : 0,
    amount : 0,
    invoice_id : null
  }
  
  gridApi.forEachNodeAfterFilter(row => {
    result.grossAmount += parseFloat(row.data.grossAmount)
    result.vat += parseFloat(row.data.vat)
    result.amount += parseFloat(row.data.amount)
  })

  result.grossAmount = result.grossAmount.toFixed(2)
  result.vat = result.vat.toFixed(2)
  result.amount = result.amount.toFixed(2)

  return [result]
}

class Invoices extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {invoices:[],selectedInvoice:null,invoicePDF:null,openEditInvoiceForm:false,deleted:false,message:"",invoice_id:null}
  }

  async componentDidMount() {
    console.log("invoices mount")

    let {invoice_id} = this.props.match.params
    
    if (invoice_id)
    {
      let userData = Config.getConfig('userData')
      
      if (userData.edit_right)
      {
        this.setState({invoice_id:parseInt(invoice_id,10)})
      }
    }
  }

  componentDidUpdate(prevProps) {

    let {invoice_id} = this.props.match.params

    if(invoice_id && prevProps.match.params.invoice_id !== invoice_id)
    {
      let userData = Config.getConfig('userData')
      
      if (userData.edit_right)
      {
        this.setState({invoice_id:parseInt(invoice_id,10)})
      }
    }

    if (this.props.searchTerm !== prevProps.searchTerm)
    {
      if (this.props.searchTerm.length)
      {
        this.searchInvoices(this.props.searchTerm)
      }
      else
      {
        this.clearSearch()
      }
    }
  }

  searchInvoices = async searchTerm => {
    this.setState({invoices:[]})
    let invoices = await callAccountsApi("invoices","searchInvoices",{searchTerm})
    this.setState({invoices})

    if (!invoices.length)
    {
      let message= "No matching invoices found"
      this.setState({message})
    }
  }

  clearSearch = () => {
    this.setState({invoices:[]})
    this.getInvoices()
  }

  getInvoices = async () => {
    let invoices = await callAccountsApi("invoices","getInvoices",{voided:this.state.deleted})
    this.setState({invoices})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))

    if (this.state.invoice_id)
    {
      invoices.forEach(i => {
        if (i.invoice_id === this.state.invoice_id)
        {
          this.setState({editedInvoice:i,openEditInvoiceForm:true})
        }
      })
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getInvoices()
  }

  onFilterChanged = params => {
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  onRowDoubleClicked = event => {
    this.openInvoiceDetails(event.node.data)
  }

  openNewInvoiceForm = () => {
    this.setState({openEditInvoiceForm:true,editedInvoice:{invoice_date : new Date()}})
  }

  closeNewInvoiceForm = () => {
    this.setState({openEditInvoiceForm:false})
  }
  
  openInvoiceDetails = selectedInvoice => {
    this.setState({selectedInvoice})
  }
    
  editInvoiceDetails = editedInvoice => {
    this.closeSelectedInvoice()
    this.setState({openEditInvoiceForm:true,editedInvoice})
  }

  actionClickHandler = (action,invoice) => {
    switch(action)
    {
      case "view" : return this.openInvoiceDetails(invoice)
      case "print" : return printInvoice(invoice.invoice_id)
      case "email" : return this.emailInvoice(invoice.invoice_id)
      case "download" : return downloadInvoice(invoice.invoice_id,invoice.invoicenumber)
      case "delete" : return this.deleteInvoice(invoice.invoice_id)
      case "undelete" : return this.undeleteInvoice(invoice.invoice_id)
      case "edit" : return this.editInvoiceDetails(invoice)
      case "link" : return this.gotoTransaction(invoice)
      default: {}
    }
  }

  gotoTransaction = data => {
    if (data.transaction_id)
    {
      this.props.history.push('/transactions/' + data.transaction_id)
    }
  }

  emailInvoice = async invoice_id => {
    await callAccountsApi("invoices","sendInvoiceToClient",{invoice_id})
    this.setState({message:"Invoice emailed to client"})
  }
  
  deleteInvoice = async invoice_id => {
    await callAccountsApi("invoices","deleteInvoice",{invoice_id})
    this.setState({message:"Invoice deleted",invoices:[]})
    this.closeSelectedInvoice()
    this.getInvoices()
  }

  undeleteInvoice = async invoice_id => {
    await callAccountsApi("invoices","undeleteInvoice",{invoice_id})
    this.setState({message:"Invoice recovered",deleted:0,invoices:[]})
    this.closeSelectedInvoice()
    this.getInvoices()
  }

  closeSelectedInvoice = () => {
    this.setState({selectedInvoice:null})
  }

  handleSavedInvoice = invoice_id => {
    this.setState({editedInvoice:null})
    this.setState({message:"Invoice saved"})
    this.getInvoices()
  }

  toggleInvoiceState = () => {
    let deleted = !this.state.deleted
    this.setState({deleted,invoices:[]},() => {
      this.getInvoices()
    })
  }

  handleCloseMessage = () => {
    this.setState({message:""})
  }

  render() {
    const { classes } = this.props

    let GridConfig = getGridConfig(this.openNewInvoiceForm.bind(this), this.actionClickHandler.bind(this))

    console.log("rendering invoices")


    return (
      <React.Fragment>
        <span style={{float:"right",color:'#fff'}}>
          {!this.props.searchTerm &&
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Invoices</Grid>
            <Grid item>
              <Switch
                  checked={this.state.deleted}
                  onChange={this.toggleInvoiceState}
                  color="primary"
                />
            </Grid>
            <Grid item>Voided invoices</Grid>
          </Grid>
        }
        </span>

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.invoices}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams : {
                            // only show count and sum ('min', 'max', 'avg' won't be shown)
                            aggFuncs : ['min', 'max', 'avg', 'sum']
                        }
                    }
                ]
              }}
              components = {{
                customPinnedRowRenderer: CustomPinnedRowRenderer,

              }}
              frameworkComponents = {{
                invoiceActionCellRenderer : ActionCellRenderer,
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              rowClassRules={{
                'voidedInvoice': 'data.voided === 1'
              }}

              onFilterChanged = {this.onFilterChanged}
              >
            </AgGridReact>
          </div>

        </Paper>

        <InvoiceDetail open={this.state.selectedInvoice !== null} invoice={this.state.selectedInvoice} close={this.closeSelectedInvoice} actionClickHandler={this.actionClickHandler} link={true}/>
        <EditInvoiceForm open={this.state.openEditInvoiceForm} close={this.closeNewInvoiceForm} handleSavedInvoice={this.handleSavedInvoice} invoice={this.state.editedInvoice}/>
        <iframe id="pdfiframe" title="pdf"/>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Invoices))