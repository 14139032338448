import Moment from 'moment-timezone'
import React, { Component } from 'react'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ValueFormatter from '../components/value-formatter'

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    transaction_date :null,    
    notes:"TOTALS",
    userBalance : null,
    balance : 0,
    amount_out : 0,
    amount_in : 0,
  }
  
  gridApi.forEachNode(row => {
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
    result.balance = row.data.balance ? parseFloat(row.data.balance) : 0
  })

  result.amount_out = result.amount_out.toFixed(2)
  result.amount_in = result.amount_in.toFixed(2)
  result.balance = result.balance.toFixed(2)

  return [result]
}

const numberSort = (num1,num2) => {
  return num1 - num2
}

class BankAccountGrid extends Component {

  onGridReady = params => {
    params.api.setPinnedBottomRowData(createTotalPinnedData(params.api))
  }
   
  dateCellClass = date => {

    if (Moment(date).year() !== this.props.year)
    {
      return "wrongYear"
    }
  }

  render() {

    return (
      <div className="ag-theme-alpine" style={ {width: '100%'} }>
        <AgGridReact
          onGridReady={this.onGridReady}
          rowData={this.props.dividends}
          onRowDoubleClicked={item => this.props.actionClickHandler("view",item.data)}
          domLayout='autoHeight'
          columnDefs={[
            {
              field: "transaction_date",
              headerName: "Date",
              menuTabs : [],
              valueFormatter : item => ValueFormatter.formatDate(item.value),
              cellClass : item => this.dateCellClass(item.value),
              sortable: true,
              filter: 'agDateColumnFilter',
              filterParams : {
                filterOptions : ['inRange','equals','empty'],
                suppressAndOrCondition : true,
                inRangeInclusive : true,
                comparator : function (filter, value) {
                  let filterMoment = Moment(filter)
                  let valueMoment = Moment(value).tz('Europe/London')
        
                  if (valueMoment.isSame(filterMoment,'day')) return 0
                  if (valueMoment.isBefore(filterMoment,'day')) return -1
                  if (valueMoment.isAfter(filterMoment,'day')) return 1
                },
              },
              width : 150,
            },
            {
              field: "type_name",
              headerName: "Type",
              cellClass : 'textGridCell',
              menuTabs : [],
              sortable: false,
              comparator: numberSort,
              filter: false,
              width : 300,
            },
            {
              field: "name",
              headerName: "Recipient",
              cellClass : 'textGridCell',
              menuTabs : [],
              sortable: false,
              comparator: numberSort,
              filter: false,
              width : 300,
            },
            {
              field: "amount_out",
              headerName: "Amount Out",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 150,
            },
            {
              field: "amount_in",
              headerName: "Amount In",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNull(item.value),
              filter: true,
              width : 150,
            },
            {
              field: "userBalance",
              headerName: "Recipient Balance",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoneyOrNullifNUll(item.value),
              filter: true,
              width : 150,
            },
            {
              field: "balance",
              headerName: "Overall Balance",
              headerClass : "rightAlignHeader",
              cellClass : 'numberGridCell',
              menuTabs : [],
              sortable: false,
              valueFormatter : item => ValueFormatter.formatMoney(item.value),
              filter: true,
              width : 150,
            },
          ]}
          components = {{
            customPinnedRowRenderer: CustomPinnedRowRenderer,
          }}
          >
        </AgGridReact>
      </div>
    )
  }
}

export default (BankAccountGrid)