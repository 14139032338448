import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Snackbar} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import ActionCellRenderer from '../components/action-cell-renderer'

import callAccountsApi from '../data-provider'

import getGridConfig from './grid-config'
import UserView from './user-view'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    financialYear : "",
    name : "TOTALS",
    amount_out : 0,
    amount_in: 0,
    balance : 0
  }
  
  gridApi.forEachNodeAfterFilter(row => {
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
  })

  result.balance = (result.amount_in + result.amount_out).toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)
  result.amount_in = result.amount_in.toFixed(2)

  return [result]
}


class Loans extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {loans:[],message:"",detail_mode:"view"}
  }

  async componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getLoans()
  }
  
  onFilterChanged = () => {
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  getLoans = async () => {
    let loans = await callAccountsApi("loans","getLoans")
    this.setState({loans})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  onRowDoubleClicked = row => {
    this.openUserView(row.data)
  }

  actionClickHandler = (action,user) => {
    switch(action)
    {
      case "view" : return this.openUserView(user)
      default: {}
    }
  }

  openUserView = user => {
    this.setState({user})
  }

  closeUserView = () => {
    this.setState({user:null})
  }

  render() {
    const { classes } = this.props

    let GridConfig = getGridConfig(this.actionClickHandler)

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.loans}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams : {
                            // only show count and sum ('min', 'max', 'avg' won't be shown)
                            aggFuncs : ['min', 'max', 'avg', 'sum']
                        }
                    }
                ]
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}
              frameworkComponents = {{
                ActionCellRenderer : ActionCellRenderer,
              }}
              components = {{
                customPinnedRowRenderer: CustomPinnedRowRenderer,
              }}
              onFilterChanged = {this.onFilterChanged}
              >
            </AgGridReact>
          </div>

        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />
        
        <UserView user={this.state.user} open={this.state.user} close={this.closeUserView}/>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Loans))