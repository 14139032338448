import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import cookie from 'react-cookies'

import Config from './config'

class Logout extends Component {

  render() {
    console.log("logout")
    
    cookie.remove('config',{expires:0, path: '/'})

    Config.deleteConfig('awsConfig')
    Config.deleteConfig('keys')
    Config.deleteConfig('userData')

    console.log("logged out, going to login form")

    return (<Redirect to="/login"/>)
  }
}

export default Logout