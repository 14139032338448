import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogTitle,DialogContent} from '@material-ui/core'

import callAccountsApi from '../data-provider'

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise'

import getGridConfig from './grid-config-popup'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
})

function CustomPinnedRowRenderer () {}

CustomPinnedRowRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div')
    this.eGui.style = params.style
    this.eGui.innerHTML = params.value
}

CustomPinnedRowRenderer.prototype.getGui = function() {
    return this.eGui
}

function createTotalPinnedData(gridApi) {

  var result = {
    financialYear : "",
    transaction_date : "",
    type_name : "TOTALS",
    amount_out : 0,
    amount_in: 0,
    balance : 0
  }
  
  gridApi.forEachNodeAfterFilter(row => {
    result.amount_out += row.data.amount_out ? parseFloat(row.data.amount_out) : 0
    result.amount_in += row.data.amount_in ? parseFloat(row.data.amount_in) : 0
  })

  result.balance = (result.amount_in + result.amount_out).toFixed(2)
  result.amount_out = result.amount_out.toFixed(2)
  result.amount_in = result.amount_in.toFixed(2)

  return [result]
}

class UserView extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {loans:[],user:{}}
  }
  
  componentDidUpdate(prevProps) {
    let user = this.props.user

    if (user && this.props.user !== prevProps.user)
    {
      this.setState({loans:[],user:user},() => {
        this.getLoans(this.props.user.user_id)
      })
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
  }

  onFilterChanged = () => {
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  getLoans = async user_id => {
    let loans = await callAccountsApi("loans","getUserLoans",{user_id})
    this.setState({loans})
    this.gridApi.setPinnedBottomRowData(createTotalPinnedData(this.gridApi))
  }

  render() {
    const { open, close } = this.props
    let GridConfig = getGridConfig()
    const loans = this.state.loans

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogTitle id="form-dialog-title">{this.state.user.name} loans</DialogTitle>
        <DialogContent>

          <div className="ag-theme-alpine" style={ {height: '600px', width: '100%'} }>
          <AgGridReact
            onGridReady={this.onGridReady}
            rowData={loans}
            columnDefs={GridConfig.columnDefs}
            enableRangeSelection={true}
            onRowDoubleClicked={this.onRowDoubleClicked}
            defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
            components = {{
              customPinnedRowRenderer: CustomPinnedRowRenderer,
            }}
            statusBar = {{
              statusPanels : [
                {
                  statusPanel: 'agTotalAndFilteredRowCountComponent',
                  align: 'left',
                },
                  {
                      statusPanel: 'agAggregationComponent',
                      statusPanelParams : {
                          // only show count and sum ('min', 'max', 'avg' won't be shown)
                          aggFuncs : ['min', 'max', 'avg', 'sum']
                      }
                  }
              ]
            }}
            sideBar={{
              toolPanels : ['filters'],
              closedByDefault : true,
            }}
            onFilterChanged = {this.onFilterChanged}
            >
          </AgGridReact>
          </div>

        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UserView)