import React from 'react'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddToPhotos'

import ValueFormatter from '../components/value-formatter'

import Config from '../config'

const getGridConfig = (actionClickHandler) => {

  let userData = Config.getConfig('userData')

  return [
    {
      field: "name",
      headerName: "Account",
      menuTabs : [],
      sortable: true,
      filter: true,
      width :300,
    },
    {
      field: "account_number",
      headerName: "Account Number",
      menuTabs : [],
      sortable: true,
      filter: true,
      width :300,
    },
    {
      field: "hide",
      headerName: "Hidden",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "expenseAccount",
      headerName: "Expenses",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "owner",
      headerName: "Owner",
      menuTabs : [],
      sortable: false,
      filter: false,
      width : 100,
    },
    {
      field: "active",
      headerName: "Active",
      menuTabs : [],
      sortable: false,
      filter: false,
      valueFormatter : item => ValueFormatter.yesNo(item.value),
      width : 100,
    },
    {
      field: "account_id",
      headerName: "Action",
      cellRenderer: 'actionCellRenderer',
      headerComponentFramework: (props) => {
        return userData.edit_right ? 
          (<Button onClick={event => actionClickHandler('new')} color="primary" startIcon={<AddIcon />}>new account</Button>) 
          : 
          (
          <div class="ag-cell-label-container">
          <div class="ag-header-cell-label">
              <span class="ag-header-cell-text"> </span>
          </div> 
        </div>)
      },
      cellRendererParams: item => {
        return {
          clicked: actionClickHandler,
          view : true,
        }
      },
      menuTabs : [],
      filter: false,
      width : 150,
    },
  ]
}

export default getGridConfig