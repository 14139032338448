import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogContent,DialogActions, TextField, Grid, IconButton, Switch, Select, FormControl, InputLabel, MenuItem} from '@material-ui/core'

import { MdClose as CloseIcon, MdSave as SaveIcon } from 'react-icons/md'
import callAccountsApi from '../data-provider'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
  income: {
    color:"green"
  },
  outgoing: {
    color:"red"
  },
})

class AccountForm extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {account:null,errorMessage:null}
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.account !== prevProps.account)
    {
      this.setState({account:this.props.account})
    }
  }

  handleChange = event => {
    let account = this.state.account

    account[event.target.name] = event.target.value

    this.setState({account})
  }

  save = async () => {
    await callAccountsApi("manage","saveAccount",{account:this.state.account})
    this.props.close(true)
  }

  render() {
    const { classes, open ,close} = this.props
    const account = this.state.account

    return account ? (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogContent>
          <Grid container spacing={3} style={{marginBottom:"30px"}}>

            <Grid item>
              <TextField
                id="name"
                name="name"
                label="Account Name"
                value={account.name ? account.name : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="account_number"
                name="account_number"
                label="Account Number"
                value={account.account_number ? account.account_number : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            {account.expenseAccount && 
              <Grid item>
                <FormControl className={classes.formControl} style={{minWidth: 300}}>
                  <InputLabel id="user_id-label">Expense Account Owner</InputLabel>
                  <Select value={account.user_id ? account.user_id : ''} onChange={this.handleInvoiceChange} name="user_id" labelId="user_id-label">
                    {this.props.users.map(u => <MenuItem key={u.user_id} value={u.user_id}>{u.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            }

          </Grid>

          <Grid container spacing={3} style={{marginBottom:"30px"}}>
            <Grid item>
              <TextField
                id="importer"
                name="importer"
                label="Importer Function"
                value={account.importer ? account.importer : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="importMimeType"
                name="importMimeType"
                label="importMimeType Number"
                value={account.importMimeType ? account.importMimeType : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

          </Grid>

          <Grid container justify="space-around" style={{marginBottom:"30px"}}>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Inactive</Grid>
                <Grid item>
                  <Switch
                    checked={account.active === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"active",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Active</Grid>
              </Grid>
            </Grid>
            
            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Visible</Grid>
                <Grid item>
                  <Switch
                    checked={account.hide === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"hide",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Hidden</Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
                <Grid item>Bank Account</Grid>
                <Grid item>
                  <Switch
                    checked={account.expenseAccount === 1}
                    onChange={(event,value) => this.handleChange({target:{name:"expenseAccount",value:value ? 1 : 0}})}
                    color="primary"
                  />
                </Grid>
                <Grid item>Expense Account</Grid>
              </Grid>
            </Grid>

          </Grid>

        </DialogContent>

        <DialogActions>
        <div style={{flex: '1 0 0'}} />
        <span className={classes.errorMessage}>{this.state.errorMessage}</span>
        <div style={{flex: '1 0 0'}} />
          
        <IconButton onClick={close} color="primary">
          <CloseIcon/>
        </IconButton>

        <IconButton onClick={this.save} color="primary">
          <SaveIcon/>
        </IconButton>
      </DialogActions>
      
      </Dialog>
    ) : ''
  }
}

export default withStyles(styles)(AccountForm)