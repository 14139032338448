import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import {Paper,Snackbar} from '@material-ui/core'

import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-enterprise/dist/styles/ag-grid.css'
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

import Moment from 'moment-timezone'

import ActionCellRenderer from '../components/action-cell-renderer'

import callAccountsApi from '../data-provider'

import VATDetail from './vat-detail'

import getGridConfig from './grid-config'
import Config from '../config'

import OAuth2Login from 'react-simple-oauth2-login'

const styles = theme => ({
  paper: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
    position : 'fixed',
    top : 120,
    bottom : 20,
    left : 20,
    right : 20,
  }
})

class Vat extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {returns:[],selectedVatReturn:null,vat_id:null,message:"",detail_mode:"view",authtoken:null}
  }

  async componentDidMount() {
    let {vat_id} = this.props.match.params

    if (vat_id)
    {
      let userData = Config.getConfig('userData')
      
      if (userData.edit_right)
      {
        this.setState({vat_id:parseInt(vat_id,10)})
      }
    }

    try
    {
      let authtoken = await callAccountsApi("vat","getStoredHmrcToken",{redirect_uri:Config.getConfig("redirect_uri"),mode:Config.getConfig("hmrc_mode")})
      this.setState({authtoken})
    }
    catch(err)
    {
      console.log("Error getting stored auth token",err)
    }
  }

  componentDidUpdate(prevProps) {

    let {vat_id} = this.props.match.params

    if(vat_id && prevProps.match.params.vat_id !== vat_id)
    {
      let userData = Config.getConfig('userData')
      
      if (userData.edit_right)
      {
        this.setState({vat_id:parseInt(vat_id,10)})
      }
    }
  }
  
  onGridReady = params => {
    this.gridApi = params.api
    this.gridColumnApi = params.columnApi
    this.getReturns()
  }

  getReturns = async () => {

    console.log("get returns")

    let returns = await callAccountsApi("vat","getReturns")
    this.setState({returns})
  }

  onRowDoubleClicked = row => {
    this.openVATDetailsPopup(row.data)
  }

  openVATDetailsPopup = selectedVatReturn => {
    this.setState({selectedVatReturn,detail_mode:"view"})
  }

  createNewReturn = async () => {

    try
    {
      if (this.state.authtoken)
      {
        let vatreturn = await callAccountsApi("vat","createNewReturn",{authtoken:this.state.authtoken,mode:Config.getConfig("hmrc_mode")})

        await this.getReturns()
        this.openVATDetailsPopup(vatreturn)  
      }
      else
      {
        this.setState({message:"Need to authenticate with HMRC first"})
      }
    }
    catch(err) // Could not talk to HMRC. Need to authenticate and try again
    {
      this.setState({message:"Need to authenticate with HMRC first"})
    }
  }
  
  closeVATDetailsPopup = (refresh) => {
    this.setState({selectedVatReturn:null})

    if (refresh)
    {
      this.getReturns()
    }
  }

  actionClickHandler = (action,vatreturn) => {
    switch(action)
    {
      case "edit" : return this.setState({detail_mode:"edit"})
      case "view" : return this.openVATDetailsPopup(vatreturn)
      case "print" : return this.print(vatreturn.vat_id)
      case "download" : return this.download(vatreturn.vat_id,vatreturn.period)
      case "link" : return this.gotoTransaction(vatreturn)
      default: {}
    }
  }

  gotoTransaction = data => {
    if (data.transaction_id)
    {
      this.props.history.push('/transactions/' + data.transaction_id)
    }
  }

  print = async vat_id => {
    let pdfData = await callAccountsApi("vat","getReturnPDF",{vat_id})

    let pdfDoc = Buffer.from(pdfData.data)
    var blob = new Blob([pdfDoc], { type: 'application/pdf' })
    var blob_url = URL.createObjectURL(blob)

    var iframe = document.getElementById('pdfiframe')
    iframe.src=blob_url
    document.body.appendChild(iframe)
    iframe.onload = function() {
      iframe.contentWindow.print()
    }
  }

  download = async (vat_id,period) => {
    let pdfData = await callAccountsApi("vat","getReturnPDF",{vat_id})
    let filename = `VAT Return ${Moment(period).format("DD-MMM-YYYY")}.pdf`

    let pdfDoc = Buffer.from(pdfData.data)
    var blob = new Blob([pdfDoc], { type: 'application/pdf' })
    var blob_url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = blob_url
    a.download = filename

    a.click()
  }

  handleCloseMessage = () => {
    this.setState({message:""})
  }

  oauthLoginSuccess = async (response) => {
    console.log("oauthLoginSuccess code:",response.code)

    console.log("calling server to swap code for token code:")

    let authtoken = await callAccountsApi("vat","getHmrcTokenFromCode",{code:response.code,redirect_uri:Config.getConfig('redirect_uri'),mode:Config.getConfig('hmrc_mode')})

    this.setState({authtoken})

    console.log("oauthLoginSuccess auth:",authtoken)

  }
  oauthLoginFailure = (response) => {
    console.log("oauthLoginFailure",response)
  }

  render() {
    const { classes } = this.props

    let GridConfig = getGridConfig(this.createNewReturn.bind(this), this.actionClickHandler.bind(this))

    return (
      <React.Fragment>

        {!this.state.authtoken &&
        <OAuth2Login
          authorizationUrl = {Config.getConfig('hmrc_url')}
          responseType="code"
          clientId={Config.getConfig('hmrc_client_id')}
          redirectUri={Config.getConfig('redirect_uri')}
          scope = "read:vat write:vat"
          state = "hfaccounts"
          buttonText = "HMRC Login"
          onSuccess={this.oauthLoginSuccess}
          onFailure={this.oauthLoginFailure}/>
        }

        <Paper className={classes.paper}>
          <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
            <AgGridReact
              onGridReady={this.onGridReady}
              rowData={this.state.returns}
              rowBuffer={GridConfig.visibleRowBufferSize*2}
              columnDefs={GridConfig.columnDefs}
              animateRows={true}
              rowSelection="multiple"
              onRowDoubleClicked={this.onRowDoubleClicked}
              defaultColDef={{filterParams:{ newRowsAction:'keep'}}}
              enableRangeSelection={true}
              statusBar = {{
                statusPanels : [
                  {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                  },
                    {
                        statusPanel: 'agAggregationComponent',
                        statusPanelParams : {
                            // only show count and sum ('min', 'max', 'avg' won't be shown)
                            aggFuncs : ['min', 'max', 'avg', 'sum']
                        }
                    }
                ]
              }}
              frameworkComponents = {{
                invoiceActionCellRenderer : ActionCellRenderer,
              }}
              sideBar={{
                toolPanels : ['filters'],
                closedByDefault : true,
              }}

              onFilterChanged = {this.onFilterChanged}
              >
            </AgGridReact>
          </div>

        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.message.length > 0}
          onClose={this.handleCloseMessage}
          autoHideDuration={3000}
          message={this.state.message}
        />
            
        <VATDetail vatreturn={this.state.selectedVatReturn} open={this.state.selectedVatReturn !== null} close={this.closeVATDetailsPopup} actionClickHandler={this.actionClickHandler} mode={this.state.detail_mode} authtoken={this.state.authtoken}/>

        <iframe id="pdfiframe" title="pdf"/>

      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(Vat))