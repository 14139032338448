const AWS = require('aws-sdk')
const Config = require('../config')

export const getFile = async (Bucket,Key) => {
  try
  {
    const S3 = new AWS.S3(Config.getConfig("awsConfig"))
    console.log("S3 config",Config.getConfig("awsConfig"))

    console.log("S3 getting",Key)
    return await S3.getObject({ Bucket,Key }).promise()
  }
  catch(err)
  {
    console.log("S3 getFile error",err)
  }
}

export const putFile = async (Bucket,Key,Body,ContentType,oldKey) => {
  try
  {
    const S3 = new AWS.S3(Config.getConfig("awsConfig"))
    await S3.putObject({ Bucket,Key,Body,ContentType }).promise()

    if (oldKey && oldKey !== Key)
    {
      try
      {
        await S3.deleteObject({Bucket,Key:oldKey}).promise()
      }
      catch(err) {} // Ignore if file does not exist
    }
  }
  catch(err)
  {
    console.log("S3 putFile error",err)
  }
}

export const deleteFile = async (Bucket,Key) => {
  try
  {
    const S3 = new AWS.S3(Config.getConfig("awsConfig"))
    await S3.deleteObject({Bucket,Key}).promise()
  }
  catch(err)
  {
    console.log("S3 putFile error",err)
  }
}