import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions, IconButton, Table, TableBody, TableCell, TableRow, TableHead} from '@material-ui/core'

import callAccountsApi from '../data-provider'

import PrintIcon from '@material-ui/icons/Print'
import EmailIcon from '@material-ui/icons/Email'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import UnDeleteIcon from '@material-ui/icons/RestoreFromTrash'
import EditIcon from '@material-ui/icons/Edit'
import { ImLink as LinkIcon } from "react-icons/im"

import ValueFormatter from '../components/value-formatter'

import Config from '../config'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
})

class InvoiceDetail extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {invoice:{},details:[],edit:false}
  }

  componentDidMount() {
    let userData = Config.getConfig('userData')
    let edit = userData.edit_right === 1
    this.setState({edit})
  }
  
  componentDidUpdate(prevProps) {
    let invoice = this.props.invoice

    if (invoice && this.props.invoice !== prevProps.invoice)
    {
      this.setState({invoice,details:null},() => {
        this.getInvoiceDetails(this.props.invoice.invoice_id)
      })
    }
  }

  getInvoiceDetails = async invoice_id => {
    let details = await callAccountsApi("invoices","getInvoiceDetails",{invoice_id})
    this.setState({details})
  }


  render() {
    const { classes, open, close } = this.props
    const invoice = this.state.invoice

    return (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
      <DialogTitle id="form-dialog-title">{this.state.invoice.invoicenumber} {this.state.invoice.voided === 1 && <span>[Invoice voided]</span>} {this.state.edit && <span className={classes.ownerText}>({this.state.invoice.owner_name} · <a href="mailto:{this.state.invoice.owner_email}">{this.state.invoice.owner_email}</a>)</span>}</DialogTitle>
      <DialogContent>

        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell className="tableHeader">Date Raised</TableCell>
              <TableCell>{ValueFormatter.formatDate(invoice.invoice_date)}</TableCell>
              <TableCell className="tableHeader">Date Paid</TableCell>
              <TableCell>{ValueFormatter.formatDate(invoice.transaction_date)}</TableCell>
              <TableCell className="tableHeader">Client</TableCell>
              <TableCell>{invoice.client}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="tableHeader">Contact</TableCell>
              <TableCell>{invoice.clientContact}</TableCell>
              <TableCell className="tableHeader">Email</TableCell>
              <TableCell><a href="mailto:{invoice.clientEmail}">{invoice.clientEmail}</a></TableCell>
              <TableCell className="tableHeader">Invoice Email</TableCell>
              <TableCell><a href="mailto:{invoice.clientInvoiceEmail}">{invoice.clientInvoiceEmail}</a></TableCell>
            </TableRow>
          </TableHead>
        </Table>

        <Table className={classes.table} >
          <TableBody>
            <TableRow>
              <TableCell className="tableHeader"></TableCell>
              <TableCell className="tableHeaderNumber">Net</TableCell>
              <TableCell className="tableHeaderNumber">VAT</TableCell>
              <TableCell className="tableHeaderNumber">Gross</TableCell>
            </TableRow>

            {this.state.details && this.state.details.map(d => (
              <TableRow key={d.detail_id}>
                <TableCell>{d.details}</TableCell>
                <TableCell className="numberGridCell">{ValueFormatter.formatMoney(d.amount)}</TableCell>
                <TableCell className="numberGridCell">{ValueFormatter.formatMoney(d.vat)}</TableCell>
                <TableCell className="numberGridCell">{ValueFormatter.formatMoney(d.grossAmount)}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell></TableCell>
              <TableCell className="numberGridCellBold">{ValueFormatter.formatMoney(invoice.amount)}</TableCell>
              <TableCell className="numberGridCellBold">{ValueFormatter.formatMoney(invoice.vat)}</TableCell>
              <TableCell className="numberGridCellBold">{ValueFormatter.formatMoney(invoice.grossAmount)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>


        {!this.state.details &&
          <DialogContentText>Loading invoice...</DialogContentText>
        }
      </DialogContent>

      <DialogActions>
        {(this.state.edit && this.state.invoice) &&
        <React.Fragment>
          {this.state.invoice.deleted === 0 &&
            <IconButton onClick={event => this.props.actionClickHandler('delete',invoice)} color="secondary">
              <DeleteIcon/>
            </IconButton>
          }

          {this.state.invoice.deleted === 1 &&
            <IconButton onClick={event => this.props.actionClickHandler('undelete',invoice)} color="secondary">
              <UnDeleteIcon/>
            </IconButton>
          }

          <div style={{flex: '1 0 0'}} />
          
          <IconButton onClick={event => this.props.actionClickHandler('edit',invoice)} color="primary">
            <EditIcon/>
          </IconButton>
          <IconButton onClick={event => this.props.actionClickHandler('email',invoice)} color="default">
            <EmailIcon/>
          </IconButton>
          {this.props.link &&
          <IconButton onClick={event => this.props.actionClickHandler('link',invoice)} color="default" disabled={this.state.invoice.transaction_id === null && this.state.invoice.expense_id === null}>
            <LinkIcon/>
          </IconButton>
          }
        </React.Fragment>
        }
        <IconButton onClick={event => this.props.actionClickHandler('download',invoice)} color="default">
          <DownloadIcon/>
        </IconButton>
        <IconButton onClick={event => this.props.actionClickHandler('print',invoice)} color="default">
          <PrintIcon/>
        </IconButton>
        <IconButton onClick={close} color="primary">
          <CloseIcon/>
        </IconButton>
      </DialogActions>

      </Dialog>
    )
  }
}

export default withStyles(styles)(InvoiceDetail)