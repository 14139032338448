import React, { Component } from 'react'
import {IconButton, Tooltip} from '@material-ui/core'
import Config from '../config'

import ViewIcon from '@material-ui/icons/Visibility'
import PrintIcon from '@material-ui/icons/Print'
import EmailIcon from '@material-ui/icons/Email'
import DownloadIcon from '@material-ui/icons/GetApp'
import { ImLink as LinkIcon } from "react-icons/im"

class ActionCellRenderer extends Component {
  constructor(props) {
    super(props)
    this.btnClickedHandler = this.btnClickedHandler.bind(this)
  }
  btnClickedHandler(action) {
   this.props.clicked(action,this.props.node.data)
  }
  render() {
    let userData = Config.getConfig('userData')
    
    return this.props.value ? (
      
      <React.Fragment>
        {this.props.view &&
          <Tooltip title="View detail">
            <IconButton onClick={event => this.btnClickedHandler('view')} color="primary"  disabled={this.props.viewDisabled}>
              <ViewIcon/>
            </IconButton> 
          </Tooltip>
        }
        
        {this.props.print &&
          <Tooltip title="Print">
            <IconButton onClick={event => this.btnClickedHandler('print')} color="default" disabled={this.props.printAndDownloadDisabled}>
              <PrintIcon/>
            </IconButton> 
          </Tooltip>
        }

        {this.props.download &&
          <Tooltip title="Download">
            <IconButton onClick={event => this.btnClickedHandler('download')} color="default"  disabled={this.props.printAndDownloadDisabled}>
            <DownloadIcon/>
            </IconButton>
          </Tooltip>
        }

        {(this.props.email && userData.edit_right) && 
          <Tooltip title="Email to client">
            <IconButton onClick={event => this.btnClickedHandler('email')} color="default">
              <EmailIcon/>
            </IconButton>
          </Tooltip>
        }

        {(this.props.link) && 
          <Tooltip title="Go to transaction">
            <IconButton onClick={event => this.btnClickedHandler('link')} color="default">
              <LinkIcon/>
            </IconButton>
          </Tooltip>
        }

      </React.Fragment>
    ) : (<div/>)
  }
}

export default ActionCellRenderer