import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {Dialog,DialogContent,DialogActions, TextField, Grid, IconButton, Switch} from '@material-ui/core'

import { MdClose as CloseIcon, MdSave as SaveIcon } from 'react-icons/md'
import callAccountsApi from '../data-provider'

const styles = theme => ({
  table: {
    width: 'calc(100% - 20)',
    marginTop: theme.spacing() * 3,
    overflowX: 'auto',
  },
  ownerText: {
    fontSize : '12px',
    float : 'right',
  },
  errorMessage: {
    color:"red"
  },
  income: {
    color:"green"
  },
  outgoing: {
    color:"red"
  },
})

class ClientForm extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {client:null,errorMessage:null}
  }

  componentDidUpdate(prevProps) {
    if (this.props.client !== prevProps.client)
    {
      let client = this.props.client

      if (client === null)
      {
        client = {active:1}
      }

      this.setState({client})
    }
  }

  handleChange = event => {
    let client = this.state.client
    client[event.target.name] = event.target.value
    this.setState({client})
  }

  save = async () => {
    await callAccountsApi("manage","saveClient",{client:this.state.client})
    this.props.close(true)
  }

  render() {
    const { classes, open ,close} = this.props
    const client = this.state.client

    return client ? (
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={close}>
        <DialogContent>
          <Grid container spacing={3} style={{marginBottom:"30px"}}>

            <Grid item>
              <TextField
                id="name"
                name="name"
                label="Client Name"
                value={client.name ? client.name : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="address"
                name="address"
                label="Address"
                value={client.address ? client.address : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
                multiline
                rows={5}
              />
            </Grid>

            <Grid item>
              <Grid component="label" container item alignItems="center" spacing={1}>
              <Grid item>Inactive</Grid>
              <Grid item>
                <Switch
                  checked={client.active === 1}
                  onChange={(event,value) => this.handleChange({target:{name:"active",value:value ? 1 : 0}})}
                  color="primary"
                />
              </Grid>
              <Grid item>Active</Grid>
            </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{marginBottom:"30px"}}>
            <Grid item>
              <TextField
                id="contact"
                name="contact"
                label="Contact Name"
                value={client.contact ? client.contact : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="phone"
                name="phone"
                label="Contact Phone"
                value={client.phone ? client.phone : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="email"
                name="email"
                label="Contact Email"
                value={client.email ? client.email : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{marginBottom:"30px"}}>
            <Grid item>
              <TextField
                id="invoiceRecipient"
                name="invoiceRecipient"
                label="Invoice Recipient"
                value={client.invoiceRecipient ? client.invoiceRecipient : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>

            <Grid item>
              <TextField
                id="invoiceEmail"
                name="invoiceEmail"
                label="Invoice Email"
                value={client.invoiceEmail ? client.invoiceEmail : ""}
                variant="outlined"
                onChange={this.handleChange}
                style={{width: 300}}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <div style={{flex: '1 0 0'}} />
          <span className={classes.errorMessage}>{this.state.errorMessage}</span>
          <div style={{flex: '1 0 0'}} />
            
          <IconButton onClick={close} color="primary">
            <CloseIcon/>
          </IconButton>

          <IconButton onClick={this.save} color="primary">
            <SaveIcon/>
          </IconButton>
        </DialogActions>
      
      </Dialog>
    ) : ''
  }
}

export default withStyles(styles)(ClientForm)